import { useEffect, useState } from "react";
import { AxiosRequestConfig } from "axios";
import axiosInstance from "services/index";

export interface IPreloadedImage {
  blobUrl: string;
  originalUrl: string;
}

// TODO: Remove this
export const usePreloadedImage = (url?: string) => {
  const [isLoading, setIsLoading] = useState(true);
  const [imageUrl, setImageUrl] = useState<string | undefined>();
  const [imageFile, setImageFile] = useState<File | undefined>();

  useEffect(() => {
    const getImage = async () => {
      try {
        const fileName = url?.split("/").pop() || "image";

        const options: AxiosRequestConfig = {
          method: "GET",
          url: url,
          responseType: "blob",
        };

        const response = await axiosInstance.request(options);

        if (response.data) {
          const blobUrl = URL.createObjectURL(response.data);
          setImageUrl(blobUrl);
          setImageFile(new File([response.data], fileName));
        }
      } finally {
        setIsLoading(false);
      }
    };

    if (url) {
      getImage();
    } else {
      setIsLoading(false);
    }
  }, [url]);

  return {
    isLoading,
    imageUrl,
    imageFile,
  };
};
