import { SvgIcon } from "./SvgIcon";

export const IconWeb = ({
  className,
  size,
}: {
  className?: string;
  size?: number;
}) => {
  return (
    <SvgIcon className={className} size={size}>
      <path
        id="Path_32833"
        data-name="Path 32833"
        d="M-.005,3.072A10.481,10.481,0,0,0,10.4-7.337,10.49,10.49,0,0,0-.016-17.746a10.479,10.479,0,0,0-10.4,10.409A10.481,10.481,0,0,0-.005,3.072ZM-2.8-9.335a1.577,1.577,0,0,0-.483-1A15.877,15.877,0,0,1,.843-16.242c.129.011.269.032.4.054a17.13,17.13,0,0,0-2.288,7.3A12.32,12.32,0,0,1-2.8-9.335Zm-4.662-2.9a9.046,9.046,0,0,1,6.456-4,17.043,17.043,0,0,0-3.47,5.446,1.547,1.547,0,0,0-.827.269A11.862,11.862,0,0,1-7.46-12.235Zm10.624.1a11,11,0,0,1,.473-3.352A9.158,9.158,0,0,1,8.1-11.075,11.481,11.481,0,0,1,4.657-9.281,1.639,1.639,0,0,0,3.421-9.9,10.955,10.955,0,0,1,3.164-12.139Zm-1.278.011a12.912,12.912,0,0,0,.333,2.739,1.484,1.484,0,0,0-.365.645q-.387.032-.773.032c-.29,0-.569-.011-.849-.021a15.622,15.622,0,0,1,2.063-6.725A12.686,12.686,0,0,0,1.885-12.128ZM-8.943-7.337a8.827,8.827,0,0,1,.816-3.717A13.636,13.636,0,0,0-6-9.421a1.763,1.763,0,0,0-.021.247,1.6,1.6,0,0,0,.688,1.321,16.566,16.566,0,0,0-.322,3.19A16.04,16.04,0,0,1-8.932-6.8C-8.943-6.972-8.943-7.154-8.943-7.337Zm17.886,0a8.977,8.977,0,0,1-.354,2.471,16.918,16.918,0,0,1-1.8.816A11.373,11.373,0,0,1,4.431-7.1a1.608,1.608,0,0,0,.526-.924A13.308,13.308,0,0,0,8.588-9.818,8.995,8.995,0,0,1,8.943-7.337ZM-1.09-7.584a17.583,17.583,0,0,0,.43,3.846,1.691,1.691,0,0,0-.408.5A16.152,16.152,0,0,1-4.377-4.1a15.6,15.6,0,0,1,.29-3.48,1.553,1.553,0,0,0,.9-.526,12.41,12.41,0,0,0,2.095.5Zm2.17.15c.322,0,.634-.011.945-.032A1.614,1.614,0,0,0,3.2-6.725,12.035,12.035,0,0,0,5.419-3.609a15.5,15.5,0,0,1-3.556.548A1.613,1.613,0,0,0,.585-4.071a15.5,15.5,0,0,1-.4-3.384C.478-7.444.779-7.434,1.08-7.434ZM-8.61-4.92A16.755,16.755,0,0,0-5.624-3.233a15.29,15.29,0,0,0,.494,3.19A9.07,9.07,0,0,1-8.61-4.92Zm8.6,6.531a8.635,8.635,0,0,1-3.48-.73,13.359,13.359,0,0,1-.816-3.6,16.594,16.594,0,0,0,3.126.763A1.639,1.639,0,0,0,.285-.859,17.263,17.263,0,0,0,1.52,1.472,8.95,8.95,0,0,1-.005,1.611ZM1.488-1.321a1.586,1.586,0,0,0,.333-.462A17.237,17.237,0,0,0,6.526-2.6c.226.172.451.344.688.5A9.09,9.09,0,0,1,2.82,1.139,15.554,15.554,0,0,1,1.488-1.321Z"
        transform="translate(10.415 17.746)"
        fill="currentColor"
      />
    </SvgIcon>
  );
};
