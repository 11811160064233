import { SvgIcon } from "./SvgIcon";

export const IconDirections = ({
  className,
  size,
}: {
  className?: string;
  size?: number;
}) => {
  return (
    <SvgIcon className={className} size={size}>
      <path
        id="Path_32834"
        data-name="Path 32834"
        d="M0,2.793A9.528,9.528,0,0,0,9.458-6.67,9.537,9.537,0,0,0-.015-16.133,9.526,9.526,0,0,0-9.468-6.67,9.528,9.528,0,0,0,0,2.793ZM-4.146-4.023v-2.4a2.37,2.37,0,0,1,2.705-2.7H1.2v-1.221a.663.663,0,0,1,1.133-.5L4.614-9.062a.855.855,0,0,1,0,1.494L2.329-5.781A.666.666,0,0,1,1.2-6.289V-7.51H-1.362c-.781,0-1.182.391-1.182,1.221v2.266a.784.784,0,0,1-.8.9C-3.862-3.115-4.146-3.457-4.146-4.023Z"
        transform="translate(9.468 16.133)"
        fill="currentColor"
      />
    </SvgIcon>
  );
};
