import { SvgIcon } from "icons/SvgIcon";

export const IconArrowRight = ({
  className,
  size,
  fill,
}: {
  className?: string;
  size?: number;
  fill?: string;
}) => {
  return (
    <SvgIcon className={className} viewBox="0 0 17.318 14.164" size={size}>
      <path
        fill={fill}
        id="Path_32616"
        data-name="Path 32616"
        d="M19.672,12.324a.913.913,0,0,0-.308-.687L13.272,5.545a1.17,1.17,0,0,0-.337-.231.884.884,0,0,0-.35-.072.9.9,0,0,0-.649.251.843.843,0,0,0-.26.632.956.956,0,0,0,.07.364.871.871,0,0,0,.192.293l2.07,2.1,3.545,3.237.268-.533-3.1-.186H3.284a.914.914,0,0,0-.671.256.979.979,0,0,0,0,1.327.914.914,0,0,0,.671.256h11.44l3.1-.187-.268-.524-3.545,3.228-2.07,2.1a.87.87,0,0,0-.192.294.96.96,0,0,0-.07.365.841.841,0,0,0,.26.631.9.9,0,0,0,.649.251.926.926,0,0,0,.661-.285l6.119-6.109A.912.912,0,0,0,19.672,12.324Z"
        transform="translate(-2.354 -5.242)"
      />
    </SvgIcon>
  );
};
