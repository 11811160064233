import { useEffect, useState } from "react";
// @ts-ignore
import { getAccessToken } from "services";
import { API_URL } from "services/apiUrl";

// @ts-ignore
import ReactImageVideoLightbox from "react-image-video-lightbox";
import { CircularProgress } from "@mui/material";

const Viewer = ({
  images = [],
  closeImageViewer,
}: {
  images?: string[];
  closeImageViewer: () => void;
}) => {
  const [token, setToken] = useState("");
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    const getToken = async () => {
      const accessToken = await getAccessToken();
      setToken(accessToken || "");
    };

    getToken();
  }, []);

  const escFunction = (event: KeyboardEvent) => {
    if (event.key === "Escape") {
      closeImageViewer();
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", escFunction, false);

    return () => {
      document.removeEventListener("keydown", escFunction, false);
    };
  }, []);

  const [imgs, setImgs] = useState<{ url: string; type: string }[]>([]);

  useEffect(() => {
    const getImages = async () => {
      if (token) {
        setLoading(true);
        try {
          const requestedImages = await Promise.all(
            images.map(async img => {
              try {
                const response = await fetch(`${API_URL}${img}`, {
                  method: "GET",
                  headers: { Authorization: token },
                  cache: "default",
                });
                const blob = await response.blob();
                const objectURL = URL.createObjectURL(blob);
                const type = img.includes(".mp4") ? "video" : "photo";
                return {
                  url: objectURL,
                  type: type,
                  img: img,
                };
              } catch (e) {
                return { url: "", type: "photo" };
              }
            }),
          );
          setImgs(requestedImages);
        } catch (e) {
        } finally {
          setLoading(false);
        }
      }
    };

    getImages();
  }, [token]);

  return (
    <div
      style={{
        zIndex: 20000,
        position: "fixed",
        background: "#000",
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        display: "flex",
        justifyContent: "center",
      }}
    >
      <>
        {loading ? (
          <CircularProgress
            sx={{
              alignSelf: "center",
              marginLeft: 3.6,
            }}
          />
        ) : (
          <ReactImageVideoLightbox
            data={imgs.map(({ url, type }) => ({
              url,
              type,
            }))}
            startIndex={0}
            onCloseCallback={closeImageViewer}
            showResourceCount={false}
          />
        )}
      </>
    </div>
  );
};

export default Viewer;
