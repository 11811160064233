import { SvgIcon } from "./SvgIcon";

export const IconFacebook = ({
  className,
  size,
}: {
  className?: string;
  size?: number;
}) => {
  return (
    <SvgIcon className={className} size={size}>
      <path
        id="Path_32834"
        data-name="Path 32834"
        d="M-6.155,2.073H6.166C8.314,2.073,9.4,1,9.4-1.128v-12.4c0-2.116-1.085-3.19-3.233-3.19H-6.155c-2.148,0-3.244,1.074-3.244,3.19v12.4C-9.4,1-8.3,2.073-6.155,2.073Z"
        transform="translate(9.4 17.399)"
        fill="currentColor"
        opacity="0.001"
      />
      <g
        id="Group_2658"
        data-name="Group 2658"
        transform="translate(-73.992 -1578.715)"
      >
        <path
          id="Container"
          d="M-6.155,2.073H6.166C8.314,2.073,9.4.988,9.4-1.128v-12.4c0-2.116-1.085-3.2-3.233-3.2H-6.155c-2.148,0-3.244,1.074-3.244,3.2v12.4C-9.4,1-8.3,2.073-6.155,2.073Z"
          transform="translate(83.393 1595.44)"
          fill="currentColor"
          opacity="0"
        />
        <path
          id="Subtraction_11"
          data-name="Subtraction 11"
          d="M10.539,18.8h0V12.317h2.543l.528-2.883h-3.07V8.414a2.178,2.178,0,0,1,.49-1.626,2.245,2.245,0,0,1,1.646-.483c.492,0,.868.012,1.086.035V3.727a10.368,10.368,0,0,0-2.043-.234,6.765,6.765,0,0,0-1.933.248A3.616,3.616,0,0,0,8.392,4.5a3.2,3.2,0,0,0-.558.662,3.886,3.886,0,0,0-.394.834,6.893,6.893,0,0,0-.311,2.2V9.434H5.191v2.883H7.129V18.59a9.317,9.317,0,0,1-2.841-1.239A9.447,9.447,0,0,1,5.741.741a9.362,9.362,0,0,1,8.914.87,9.448,9.448,0,0,1,1.759,14.1A9.374,9.374,0,0,1,10.539,18.8Z"
          transform="translate(73.992 1578.715)"
          fill="currentColor"
        />
      </g>
    </SvgIcon>
  );
};
