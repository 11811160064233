import { useMap, useMapsLibrary } from "@vis.gl/react-google-maps";
import { useEffect, useMemo } from "react";

export const convertStringToCoordinates = (str: string) => {
  const coordinates = str.replace(/\s/g, "").split(",");
  const lat = !isNaN(Number(coordinates?.[0]))
    ? Number(coordinates?.[0])
    : null;
  const lng = !isNaN(Number(coordinates?.[1]))
    ? Number(coordinates?.[1])
    : null;
  if (lat && lng) {
    return {
      lat,
      lng,
      lontat: [lng, lat],
    };
  }

  return null;
};

interface Coordinate {
  latitude: number;
  longitude: number;
}

export function getCoordinatesAlongLine(
  coordinates: Coordinate[],
): Coordinate[] {
  const result: Coordinate[] = [];

  for (let i = 0; i < coordinates.length - 1; i++) {
    const lat1 = coordinates[i].latitude;
    const lon1 = coordinates[i].longitude;
    const lat2 = coordinates[i + 1].latitude;
    const lon2 = coordinates[i + 1].longitude;

    const distanceBetweenPoints = distance_on_geoid(lat1, lon1, lat2, lon2);
    const pointsCount = distanceBetweenPoints / 50;

    const dLat = (lat2 - lat1) / pointsCount;
    const dLon = (lon2 - lon1) / pointsCount;

    for (let j = 0; j < pointsCount; j += 1) {
      const newLat = lat1 + j * dLat;
      const newLon = lon1 + j * dLon;

      result.push({ latitude: newLat, longitude: newLon });
    }
  }

  return result;
}

export const distance_on_geoid = (
  lat1: number,
  lon1: number,
  lat2: number,
  lon2: number,
) => {
  // Convert degrees to radians
  lat1 = (lat1 * Math.PI) / 180.0;
  lon1 = (lon1 * Math.PI) / 180.0;
  lat2 = (lat2 * Math.PI) / 180.0;
  lon2 = (lon2 * Math.PI) / 180.0;
  // radius of earth in metres
  const r = 6378100;
  // P
  const rho1 = r * Math.cos(lat1);
  const z1 = r * Math.sin(lat1);
  const x1 = rho1 * Math.cos(lon1);
  const y1 = rho1 * Math.sin(lon1);
  // Q
  const rho2 = r * Math.cos(lat2);
  const z2 = r * Math.sin(lat2);
  const x2 = rho2 * Math.cos(lon2);
  const y2 = rho2 * Math.sin(lon2);
  // Dot product
  const dot = x1 * x2 + y1 * y2 + z1 * z2;
  const cos_theta = dot / (r * r);
  const theta = Math.acos(cos_theta);
  // Distance in Metres
  return r * theta;
};

export function MapPolygons({ polygons }: { polygons: number[][][] }) {
  const map = useMap();
  const maps = useMapsLibrary("maps");

  // @ts-ignore
  const mapPolygons = useMemo(() => {
    return maps
      ? polygons.map(polygonCoords => {
          const flightPath = new maps.Polygon({
            paths: polygonCoords.map(item => ({
              lat: item[1],
              lng: item[0],
            })),
            geodesic: true,
            strokeColor: "#00c6be",
            strokeOpacity: 1.0,
            strokeWeight: 2,
            fillColor: "#00c6be",
            fillOpacity: 0.15,
          });

          return flightPath;
        })
      : [];
  }, [polygons, map]);

  useEffect(() => {
    mapPolygons.map(polygon => polygon.setMap(map));

    return () => {
      mapPolygons.map(polygon => polygon.setMap(null));
    };
  }, [mapPolygons]);

  return null;
}

export function MapRoutePoints({
  routesPoints,
}: {
  routesPoints: number[][][];
}) {
  const map = useMap();
  const maps = useMapsLibrary("maps");

  const mapRoutePoints = useMemo(() => {
    return maps
      ? routesPoints.map(routePoint => {
          const flightPath = new maps.Polyline({
            path: routePoint.map(item => ({
              lat: item[1],
              lng: item[0],
            })),
            geodesic: true,
            strokeColor: "rgb(217,54,54)",
            strokeOpacity: 0.46,
            strokeWeight: 4,
          });
          return flightPath;
        })
      : [];
  }, [map, routesPoints]);

  useEffect(() => {
    mapRoutePoints.map(polyline => polyline.setMap(map));

    return () => {
      mapRoutePoints.map(polyline => polyline.setMap(null));
    };
  }, [mapRoutePoints]);

  return null;
}
