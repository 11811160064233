import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  DiscountProgramBrand,
  DiscountProgramBrandLocation,
  DiscountProgramGoodToKnowCategory,
} from "services/borbalo-main.service";

export type PreviewLanguage = "en" | "ka";

const initialState: {
  currentBrand?: DiscountProgramBrand;
  locations: DiscountProgramBrandLocation[];
  goodToKnowCategories: DiscountProgramGoodToKnowCategory[];
  previewLanguage: PreviewLanguage;
} = {
  locations: [],
  goodToKnowCategories: [],
  previewLanguage: "en",
  currentBrand: undefined,
};

export const locationsSlice = createSlice({
  name: "locations",
  initialState: initialState,
  reducers: {
    setLocations: (
      state,
      { payload }: PayloadAction<DiscountProgramBrandLocation[]>,
    ) => {
      state.locations = payload;
    },
    setGoodToKnowCategories: (
      state,
      { payload }: PayloadAction<DiscountProgramGoodToKnowCategory[]>,
    ) => {
      state.goodToKnowCategories = payload;
    },
    setPreviewLanguage: (
      state,
      { payload }: PayloadAction<PreviewLanguage>,
    ) => {
      state.previewLanguage = payload;
    },
    setCurrentBrand: (
      state,
      { payload }: PayloadAction<DiscountProgramBrand>,
    ) => {
      state.currentBrand = payload;
    },
  },
});

export const {
  setLocations,
  setGoodToKnowCategories,
  setPreviewLanguage,
  setCurrentBrand,
} = locationsSlice.actions;

export default locationsSlice.reducer;
