import { SvgIcon } from "./SvgIcon";

export const IconChevronDown = ({
  className,
  size,
}: {
  className?: string;
  size?: number;
}) => {
  return (
    <SvgIcon className={className} size={size}>
      <path
        fill="currentColor"
        d="M11.997 17.667a1.175 1.175 0 0 1-.846-.373L2.34 8.28A1.106 1.106 0 0 1 2 7.471a1.1 1.1 0 0 1 .326-.812 1.1 1.1 0 0 1 .811-.326c.306-.003.602.114.823.326l8.05 8.237 8.042-8.237c.217-.21.508-.328.81-.326a1.1 1.1 0 0 1 .812.326A1.1 1.1 0 0 1 22 7.47a1.085 1.085 0 0 1-.326.798l-8.817 9.026a1.108 1.108 0 0 1-.86.373Z"
      />
    </SvgIcon>
  );
};
