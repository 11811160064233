import { SvgIcon } from "./SvgIcon";

export const IconTiktok = ({
  className,
  size,
}: {
  className?: string;
  size?: number;
}) => {
  return (
    <SvgIcon className={className} size={size}>
      <path
        id="Path_32835"
        data-name="Path 32835"
        d="M-6.155,2.073H6.166C8.314,2.073,9.4,1,9.4-1.128v-12.4c0-2.116-1.085-3.19-3.233-3.19H-6.155c-2.148,0-3.244,1.074-3.244,3.19v12.4C-9.4,1-8.3,2.073-6.155,2.073Z"
        transform="translate(9.399 17.399)"
        fill="currentColor"
        opacity="0"
      />
      <g
        id="Group_2659"
        data-name="Group 2659"
        transform="translate(-95.997 -1578.715)"
      >
        <path
          id="Container"
          d="M-6.155,2.073H6.166C8.314,2.073,9.4.988,9.4-1.128v-12.4c0-2.116-1.085-3.2-3.233-3.2H-6.155c-2.148,0-3.244,1.074-3.244,3.2v12.4C-9.4,1-8.3,2.073-6.155,2.073Z"
          transform="translate(105.396 1595.44)"
          fill="currentColor"
          opacity="0"
        />
        <path
          id="tiktok-svgrepo-com"
          d="M18.6,6.4a4.5,4.5,0,0,1-3.543-3.989V2H11.819V14.849a2.722,2.722,0,0,1-4.888,1.638h0a2.721,2.721,0,0,1,2.991-4.239V8.959a5.947,5.947,0,1,0,5.134,5.891V8.284a7.69,7.69,0,0,0,4.486,1.434V6.5A4.54,4.54,0,0,1,18.6,6.4Z"
          transform="translate(94.045 1576.715)"
          fill="currentColor"
        />
      </g>
    </SvgIcon>
  );
};
