import { IconArrowRight } from "./assets/IconArrowRight";
import { AdBanner } from "services/borbalo-main.service";
import "./styles.css";

const MediumBannerPreview = ({
  data,
  images,
  isGe,
}: {
  data: AdBanner;
  images: { [id: string]: string }[];
  isGe?: boolean;
}) => {
  const color = data.metadata?.textColor ?? "#000";
  const image = images.find(item => !!item[data.id])?.[data.id];
  const texts = isGe
    ? {
        title: data.metadata?.titleCaptionGe,
        callToAction: data.metadata?.callToActionCaptionGe,
      }
    : {
        title: data.metadata?.titleCaptionEn,
        callToAction: data.metadata?.callToActionCaptionEn,
      };

  return (
    <div>
      <div
        className={"medium-banner-wrapper"}
        style={{
          backgroundColor: data.metadata?.backgroundColor || "#fff",
        }}
      >
        <div className={"lagre-banner-text-wrapper"}>
          <div
            className="medium18-text color-black"
            style={{
              maxHeight: 44,
              overflow: "hidden",
              overflowWrap: "anywhere",
              color,
            }}
          >
            {texts.title}
          </div>

          <div className="row-align-center">
            <div
              className="regular12-text color-black"
              style={{
                maxHeight: 14,
                overflow: "hidden",
                color,
              }}
            >
              {texts.callToAction}
              &nbsp;&nbsp;
            </div>
            <IconArrowRight size={11} fill={color} />
          </div>
        </div>
        <div
          style={{
            width: 120,
            height: 106,
          }}
        >
          {image && (
            <img
              src={image}
              alt="large banner"
              width={120}
              height={106}
              style={{
                objectFit: "contain",
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default MediumBannerPreview;
