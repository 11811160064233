import {
  Autocomplete,
  Box,
  Button,
  Input,
  InputLabel,
  TextField,
} from "@mui/material";
import { FineCameraType } from "services/borbalo-main.service";
import { locationTypes } from "pages/FinesLocations";

const InputsSection = ({
  setIsMarker,
  newWordLocation,
  setNewWordLocation,
  maxSpeed,
  setMaxSpeed,
  type,
  setType,
  region,
  setRegion,
  municipality,
  setMunicipality,
  handleAddNewLocation,
  isLoading,
  newCoordinates,
  setNewCoordinates,
  setMarkerPosition,
  setMapRegion,
  handleClose,
}: {
  setIsMarker: React.Dispatch<React.SetStateAction<boolean>>;
  newWordLocation: string;
  setNewWordLocation: React.Dispatch<React.SetStateAction<string>>;
  maxSpeed: number;
  setMaxSpeed: React.Dispatch<React.SetStateAction<number>>;
  type: {
    value: FineCameraType;
    label: string;
  };
  setType: React.Dispatch<
    React.SetStateAction<{
      value: FineCameraType;
      label: string;
    }>
  >;
  municipality: string;
  setMunicipality: React.Dispatch<React.SetStateAction<string>>;
  handleAddNewLocation: () => void;
  isLoading: boolean;
  newCoordinates: string;
  setNewCoordinates: React.Dispatch<React.SetStateAction<string>>;
  setMarkerPosition: React.Dispatch<
    React.SetStateAction<
      | {
          lat: number;
          lng: number;
        }
      | undefined
    >
  >;
  region: string;
  setRegion: React.Dispatch<React.SetStateAction<string>>;
  setMapRegion: React.Dispatch<
    React.SetStateAction<{
      lat: number;
      lng: number;
    }>
  >;
  handleClose: () => void;
}) => {
  return (
    <>
      <Box
        sx={{
          gap: 3,
          marginBottom: 0,
          background: "#fff",
          padding: 2,
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-around",
          flexWrap: "wrap",
        }}
      >
        <div
          style={{
            width: 600,
          }}
        >
          <InputLabel>Fine Corrected Location (in Georgian)</InputLabel>
          <Input
            value={newWordLocation}
            onChange={e => setNewWordLocation(e.target.value)}
            placeholder="Tbilisi ..."
            style={{
              width: 600,
            }}
          />
        </div>
        <div
          style={{
            width: 100,
          }}
        >
          <InputLabel>Max Speed</InputLabel>
          <Input
            value={maxSpeed}
            onChange={e =>
              setMaxSpeed(pr =>
                isNaN(Number(e.target.value)) ? pr : Number(e.target.value),
              )
            }
            style={{
              width: 100,
            }}
          />
        </div>
        <div
          style={{
            width: 300,
          }}
        >
          <InputLabel>Coordinates</InputLabel>
          <Input
            value={newCoordinates}
            onChange={e => {
              setNewCoordinates(e.target.value);

              const data = e.target.value.replace(/\s/g, "").split(",");
              const lat = !isNaN(Number(data?.[0])) ? Number(data?.[0]) : null;
              const lng = !isNaN(Number(data?.[1])) ? Number(data?.[1]) : null;
              if (lat && lng) {
                setMarkerPosition({
                  lat,
                  lng,
                });
                setMapRegion({
                  lat,
                  lng,
                });
                setIsMarker(true);
              }
            }}
            placeholder="34.937561, 33.597399"
            style={{
              width: 300,
            }}
          />
        </div>
        <div
          style={{
            width: 200,
          }}
        >
          <Autocomplete
            disablePortal
            id="combo-box-demo"
            options={locationTypes}
            sx={{ width: 200 }}
            value={type}
            onChange={(_, value) => {
              if (value) {
                setType(value);
              }
            }}
            getOptionLabel={option => option.label || ""}
            renderInput={params => (
              <TextField
                {...params}
                inputProps={{
                  ...params.inputProps,
                }}
                label=""
              />
            )}
          />
        </div>
        <div
          style={{
            width: 200,
          }}
        >
          <InputLabel>Region</InputLabel>
          <Input
            value={region}
            onChange={e => setRegion(e.target.value)}
            style={{
              width: 200,
            }}
          />
        </div>
        <div
          style={{
            width: 200,
          }}
        >
          <InputLabel>Municipality</InputLabel>
          <Input
            value={municipality}
            onChange={e => setMunicipality(e.target.value)}
            style={{
              width: 200,
            }}
          />
        </div>
        <Button onClick={handleAddNewLocation} disabled={isLoading}>
          Save
        </Button>
        <Button onClick={handleClose} disabled={isLoading}>
          Cancel
        </Button>
      </Box>
    </>
  );
};

export default InputsSection;
