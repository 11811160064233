import styled from "@emotion/styled";
import { InputBase } from "@mui/material";
import debounce from "debounce";
import { useState } from "react";
import SearchIcon from "@mui/icons-material/Search";

const Search = styled("div")(() => ({
  position: "relative",
  borderRadius: 4,
  backgroundColor: "#fff",
  borderWidth: 1,
  marginLeft: 0,
  width: 300,
}));

const SearchIconWrapper = styled("div")(() => ({
  padding: 6,
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(() => ({
  color: "inherit",
  width: "100%",
  "& .MuiInputBase-input": {
    padding: 7,
    paddingLeft: 32,
  },
}));

export const useSearch = () => {
  const [search, setSearch] = useState<string>("");

  const handleSearch = debounce((e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  }, 500);

  const SearchComponent = (
    <Search>
      <SearchIconWrapper>
        <SearchIcon style={{ color: "#00c6be" }} />
      </SearchIconWrapper>
      <StyledInputBase
        onChange={handleSearch}
        placeholder="Search…"
        inputProps={{ "aria-label": "search" }}
      />
    </Search>
  );

  return {
    search,
    handleSearch,
    SearchComponent,
  };
};
