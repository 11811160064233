import { useEffect, useRef } from "react";
import clsx from "clsx";
import { IconChevronRight } from "icons/IconChevronRight";
import styles from "./CompanyPreview.module.scss";

export const DiscountRulesPreview = ({
  discountRules,
  isEn,
  name,
}: {
  discountRules: string;
  isEn: boolean;
  name: string;
}) => {
  const areaRef = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    if (areaRef.current) {
      areaRef.current.style.height = "1px";
      areaRef.current.style.height = areaRef.current.scrollHeight + "px";
    }
  }, [discountRules]);

  const texts = {
    title: isEn ? "Discount Rules" : "ფასდაკლების წესები",
    description: isEn ? (
      <>
        Company, <strong>{name}</strong>, is offering you a special discount
        through Borbalo’s Magic Card. This discount is subject to the following
        <span className="color-main pointer"> Terms and Conditions </span>
      </>
    ) : (
      <>
        კომპანია, <strong>{name}</strong>, გთავაზობთ სპეციალურ ფასდაკლებას
        „ბორბალოს ჯადოსნური ბარათით“. ფასდაკლება ექვემდებარება კომპანიის მიერ
        მითითებულ
        <span className="color-main pointer"> წესებსა და პირობებს </span>
      </>
    ),
    madgicCard: isEn ? (
      <>
        The discount provided through Borbalo’s Magic Card is subject to
        <span className="color-main pointer">
          {" "}
          General Terms and Conditions{" "}
        </span>
      </>
    ) : (
      <>
        „ბორბალოს ჯადოსნური ბარათით“ გაცემული ფასდაკლება ექვემდებარება
        <span className="color-main pointer"> ზოგად წესებსა და პირობებს </span>
      </>
    ),
    summaryTitle: isEn
      ? "Summary of Company Terms"
      : "კომპანიის პირობების შეჯამება",
    summaryDescription: isEn
      ? "The summary is made by artificial intelligence. Read the full terms and conditions here"
      : "შეჯამება გაკეთებულია ხელოვნული ინტელექტის მიერ. გაეცანით სრულ პირობებს აქ",
  };

  return (
    <div className={styles["wrapper"]}>
      <div className={styles["header"]}>
        <img
          src={"/assets/company/preview/arrow-left.svg"}
          width={20}
          height={16}
          alt={"arrow-left"}
        />
      </div>
      <div className={clsx(styles["content"])}>
        <div className="px16">
          <div className="medium24-text mb16 color-white">{texts.title}</div>
          <div className={clsx("mb16", styles["separator"])} />
          <div className="regular14-text color-white mb16">
            {texts.description}
            <span className="pointer">
              <IconChevronRight
                className={clsx("color-main", styles["arrow-icon"])}
                size={14}
              />
            </span>
          </div>
          <div className={clsx("mb16", styles["separator"])} />
          <div className="regular14-text color-white mb16">
            {texts.madgicCard}
            <span className="pointer">
              <IconChevronRight
                className={clsx("color-main", styles["arrow-icon"])}
                size={14}
              />
            </span>
          </div>
          <div className={clsx("mb32", styles["separator"])} />
          <img
            src={"/assets/company/preview/company-summary.svg"}
            width={35}
            height={38}
            className="mb8"
            alt="Company Summary"
          />
          <div className="medium20-text color-white mb8">
            {texts.summaryTitle}
          </div>
          <div className="regular14-text color-whiteGrey mb16">
            {texts.summaryDescription}{" "}
            <span className="pointer">
              <IconChevronRight
                className={clsx("color-whiteGrey", styles["arrow-icon"])}
                size={14}
              />
            </span>
          </div>
          <textarea
            ref={areaRef}
            className="regular16-text color-white mb16"
            style={{
              backgroundColor: "transparent",
              border: 0,
              resize: "none",
              fontSize: 16,
              width: "100%",
            }}
            disabled
            value={discountRules}
          ></textarea>

          <div className={clsx("mb32", styles["separator"])} />
          {/* 
          <Image
            src={"/assets/company/preview/general-summary.svg"}
            width={35}
            height={38}
            className="mb8"
            alt="General Summary"
          />
          <div className="medium20-text color-white mb8">
            Summary of General Terms
          </div>
          <div className="regular14-text color-whiteGrey mb16">
            Read the full terms and conditions here{" "}
            <span className="pointer">
              <IconChevronRight
                className={clsx("color-whiteGrey", styles["arrow-icon"])}
                size={14}
              />
            </span>
          </div>
          <div className="regular16-text color-white">
            • Card sharing is prohibited
            <br />
            <br />
            • Card sharing is prohibited
            <br />
            <br />
            • Card sharing is prohibited
            <br />
            <br />• Card sharing is prohibited
          </div> */}
        </div>
      </div>
    </div>
  );
};
