import axios from "axios";
import { API_URL } from "services/apiUrl";
import { logout } from "store/auth/slice";
import { RequestErrors, toggleError } from "store/error/slice";
import { getTokensFromStorage, setTokensDataToKeychain } from "utils/tokens";
import { store } from "../store";
import {
  AccountService,
  RefreshTokenRequest,
  TokenResponse,
} from "./borbalo-main.service";

const REFRESH_TOKEN_URL = "/api/main/account/refresh-token";

const instance = axios.create({
  baseURL: API_URL,
  timeout: 60000,
});

let refreshPromise: Promise<TokenResponse> | null = null;

const getNewAccessToken = async (refreshToken: string) => {
  try {
    if (!refreshPromise) {
      refreshPromise = new AccountService(undefined, instance).refreshToken(
        new RefreshTokenRequest({
          refreshToken,
          clientId: "borbalo-mobile",
          grantType: "refresh_token",
        }),
      );
    }

    const data = await refreshPromise;
    setTokensDataToKeychain(data);

    return `${data.token_type} ${data.access_token}`;
  } catch (e: any) {
    if ([400, 401, 403].includes(e?.status)) {
      store.dispatch(logout());
      return null;
    }

    store.dispatch(toggleError(RequestErrors.server));
    return null;
  } finally {
    refreshPromise = null;
  }
};

export const getAccessToken = async () => {
  const tokens = await getTokensFromStorage();

  if (!tokens) {
    store.dispatch(logout());
    return undefined;
  }

  let accessToken: string | null = tokens.accessToken;

  if (tokens.isAccessTokenExpired()) {
    accessToken = await getNewAccessToken(tokens.refreshToken);
  }

  if (tokens.isRefreshTokenExpired()) {
    store.dispatch(logout());
    return null;
  }

  return accessToken;
};

instance.interceptors.request.use(async config => {
  if (config.url !== REFRESH_TOKEN_URL) {
    const accessToken = await getAccessToken();

    if (accessToken === null) {
      throw new Error();
    }

    if (accessToken) {
      config.headers.Authorization = accessToken;
    }
  } else {
    config.headers["Content-Type"] = "application/json";
  }

  return config;
});

instance.interceptors.response.use(
  response => response,
  async error => {
    if (
      error.response?.status !== 401 ||
      error.response.config.url === REFRESH_TOKEN_URL
    ) {
      return Promise.reject(error);
    }

    const accessToken = await getAccessToken();
    if (accessToken) {
      error.response.config.headers.Authorization = accessToken;
      return axios(error.response.config);
    }

    return Promise.reject(error);
  },
);

export default instance;
