import { createSelector } from "reselect";
import type { RootState } from "store";

const errorState = (state: RootState) => state.error;

export const errorSelector = createSelector([errorState], ({ error }) => error);

export const isRetryButtonSelector = createSelector(
  [errorState],
  ({ isRetryButton }) => isRetryButton,
);
