import { useEffect, useState } from "react";
// @ts-ignore
import { getAccessToken } from "services";
import { API_URL } from "services/apiUrl";

import { CircularProgress, Typography } from "@mui/material";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "./styles.css";

const ImageVideoCarousel = ({ images = [] }: { images?: string[] }) => {
  const [token, setToken] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const getToken = async () => {
      const accessToken = await getAccessToken();
      setToken(accessToken || "");
    };

    getToken();
  }, []);

  const [imgs, setImgs] = useState<{ url: string; type: string }[]>([]);

  useEffect(() => {
    const getImages = async () => {
      if (token) {
        setLoading(true);
        try {
          await Promise.allSettled(
            images.map(async img => {
              await fetch(`${API_URL}${img}`, {
                method: "GET",
                headers: { Authorization: token },
                cache: "default",
              })
                .then(function (response) {
                  if (response.ok) {
                    return response.blob();
                  } else {
                    return response.json();
                  }
                })
                .then(function (myBlob) {
                  var objectURL = URL.createObjectURL(myBlob);

                  setImgs(s => [
                    ...s,
                    {
                      url: objectURL,
                      type: img.includes(".mp4") ? "video" : "photo",
                    },
                  ]);
                })
                .catch(function (error) {
                  setImgs(s => [...s, { url: "", type: "photo" }]);
                });
            }),
          );
        } catch (e) {
        } finally {
          setLoading(false);
        }
      }
    };

    getImages();
  }, [token]);

  const items = imgs.map(({ url, type }) => (
    <div
      key={url + type}
      className="item"
      style={{ width: "100%", height: 300 }}
    >
      {type === "photo" ? (
        <img
          src={url}
          className="media"
          style={{
            height: 300,
            objectFit: "contain",
          }}
          alt="media"
        />
      ) : (
        <video
          autoPlay
          height={300}
          controls
          className="media"
          style={{
            height: 300,
            maxHeight: 300,
          }}
        >
          <source src={url} />
          Your browser does not support the video tag.
        </video>
      )}
    </div>
  ));

  return (
    <div
      style={{
        display: "flex",
        height: 300,
        width: "100%",
        justifyContent: "center",
        position: "relative",
        overflow: "hidden",
      }}
    >
      <>
        {images.length === 0 && (
          <Typography
            variant="h3"
            mb={1.6}
            color={"#fff"}
            sx={{ display: "flex", alignItems: "center" }}
          >
            No pictures
          </Typography>
        )}
        {loading ? (
          <CircularProgress
            sx={{
              alignSelf: "center",
              marginLeft: 3.6,
            }}
          />
        ) : (
          <Carousel dynamicHeight={false} showThumbs={false}>
            {items}
          </Carousel>
        )}
      </>
    </div>
  );
};

export default ImageVideoCarousel;
