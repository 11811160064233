import { SvgIcon } from "./SvgIcon";

export const IconChevronRight = ({
  className,
  size,
}: {
  className?: string;
  size?: number;
}) => {
  return (
    <SvgIcon className={className} size={size}>
      <path
        fill="currentColor"
        d="M17.302 11.153a1.161 1.161 0 0 1 .363.847 1.161 1.161 0 0 1-.363.846l-9.02 8.815a1.106 1.106 0 0 1-.81.339 1.09 1.09 0 0 1-.81-.333 1.12 1.12 0 0 1-.327-.816 1.153 1.153 0 0 1 .327-.81L14.896 12 6.662 3.947a1.1 1.1 0 0 1-.327-.81 1.1 1.1 0 0 1 .327-.81A1.1 1.1 0 0 1 7.472 2a1.105 1.105 0 0 1 .81.34l9.02 8.813Z"
      />
    </SvgIcon>
  );
};
