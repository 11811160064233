import { useEffect, useState } from "react";

import {
  Box,
  Button,
  CircularProgress,
  ClassNameMap,
  Input,
  InputLabel,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import {
  FineCamera,
  FinesGroupedByCamera,
  MergeRecognizedCamerasRequest,
} from "services/borbalo-main.service";
import useAsync from "hooks/useAsync";
import { adminFinesService } from "services/fines";
import Selector from "pages/Fines/Selector";

const MergeModal = ({
  items,
  closeImageViewer,
  header,
  classes,
  requestFines,
  resetItemsForMerge,
}: {
  items: FinesGroupedByCamera[];
  closeImageViewer: () => void;
  header: {
    title: string;
    field?: keyof FinesGroupedByCamera | undefined;
  }[];
  classes: ClassNameMap<
    "table" | "text" | "group" | "tableCell" | "withoutBorder"
  >;
  requestFines: (callback: () => void) => void;
  resetItemsForMerge: () => void;
}) => {
  const allLocations = items
    .map(item => item.camera || undefined)
    .filter(item => item !== undefined);
  const [selectorValues, setSelectorValues] = useState<
    FineCamera | undefined | null
  >(allLocations?.[0]);
  const mergeRecognizedCamerasAsync = useAsync<void, any>();
  const [newWordLocation, setNewWordLocation] = useState(
    items[items.length - 1]?.locationFromImageLatin,
  );

  const isLoading =
    mergeRecognizedCamerasAsync.isLoading ||
    mergeRecognizedCamerasAsync.isSuccess;

  const escFunction = (event: KeyboardEvent) => {
    if (event.key === "Escape") {
      closeImageViewer();
    }
  };
  const confirmMerge = () => {
    mergeRecognizedCamerasAsync.run(
      adminFinesService.mergeRecognizedCameras(
        new MergeRecognizedCamerasRequest({
          latinLocationsFromImages: items.map(
            item => item.locationFromImageLatin,
          ),
          region: items[items.length - 1].region,
          municipality: items[items.length - 1].municipality,
          newLocation: newWordLocation,
          cameraId: selectorValues?.id || undefined,
        }),
      ),
    );
  };

  useEffect(() => {
    if (mergeRecognizedCamerasAsync.isSuccess) {
      resetItemsForMerge();
      requestFines(closeImageViewer);
    }
  }, [mergeRecognizedCamerasAsync.isSuccess]);

  useEffect(() => {
    document.addEventListener("keydown", escFunction, false);

    return () => {
      document.removeEventListener("keydown", escFunction, false);
    };
  }, []);

  return (
    <div
      style={{
        zIndex: 20000,
        position: "fixed",
        background: "rgba(0, 0, 0, 0.5)",
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        display: "flex",
        minWidth: 1000,
        justifyContent: "center",
      }}
    >
      <>
        <TableContainer
          component={Paper}
          style={{
            alignSelf: "center",
            width: "90%",
          }}
        >
          <div
            style={{
              maxHeight: window.innerHeight - 200,
              overflowY: "auto",
              width: "100%",
            }}
          >
            <Table
              className={classes.table}
              size="small"
              aria-label="my table"
              stickyHeader
            >
              <TableHead>
                <TableRow>
                  {header
                    .filter(
                      headerItem =>
                        headerItem.title !== "Group Id" &&
                        headerItem.title !== "Number of fines" &&
                        headerItem.title !== "Pictures" &&
                        headerItem.title !== "Corrected Location",
                    )
                    .map((headerItem, index) => (
                      <TableCell key={headerItem.title}>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            cursor: "pointer",
                            color: !headerItem.field ? "grey" : undefined,
                          }}
                        >
                          {headerItem.title}
                        </Box>
                      </TableCell>
                    ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {items.map((item, index) => (
                  <TableRow
                    key={item.locationFromImageLatin + item.cameraGroupId}
                    style={{
                      backgroundColor: "rgba(0, 198, 190, 0.1)",
                    }}
                  >
                    <TableCell component="th" scope="row">
                      {item.region}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {item.municipality}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {item.locationFromImageLatin}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {item.locationFromImageTranscription}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
          <Box
            sx={{
              display: "flex",
              background: "#fff",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              gap: 15,
              marginTop: 2,
              marginBottom: 2,
            }}
          >
            <div>
              <InputLabel>Location From Image en</InputLabel>
              <Input
                value={newWordLocation}
                onChange={e => setNewWordLocation(e.target.value)}
                placeholder="Tbilisi ..."
                style={{
                  width: 450,
                }}
              />
            </div>
            <div>
              <InputLabel>Corrected Location</InputLabel>
              <Selector
                setValue={(value: FineCamera | null | undefined) =>
                  setSelectorValues(value)
                }
                defaultValue={allLocations?.[0]}
                allLocations={[
                  new FineCamera({
                    id: "",
                    name: "Without corrected location",
                    coordinates: "",
                    createdAt: new Date(),
                    createdBy: "",
                    modifiedBy: "",
                    modifiedAt: new Date(),
                    region: "",
                    municipality: "",
                  }),
                  ...allLocations,
                ]}
              />
            </div>
            {isLoading ? (
              <CircularProgress
                sx={{
                  alignSelf: "center",
                  marginLeft: 3.6,
                }}
              />
            ) : (
              <Button onClick={confirmMerge} disabled={isLoading}>
                Confirm Rename
              </Button>
            )}
            <Button
              onClick={() => {
                closeImageViewer();
              }}
            >
              Cancel
            </Button>
          </Box>
        </TableContainer>
      </>
    </div>
  );
};

export default MergeModal;
