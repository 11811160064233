import {
  Box,
  Button,
  CircularProgress,
  InputLabel,
  Modal,
  TextareaAutosize,
  Typography,
} from "@mui/material";
import useAsync from "hooks/useAsync";
import { useEffect, useState } from "react";
import { adminFineArticlesService } from "services/adminFineArticles";
import {
  CreateOrUpdateFineArticleModel,
  FineArticleWithDefinedValues,
} from "services/borbalo-main.service";

const AddDescriptionModal = ({
  open,
  toggleModal,
  requestData,
}: {
  open?: FineArticleWithDefinedValues | null;
  toggleModal: () => void;
  requestData: (newArticle: FineArticleWithDefinedValues) => void;
}) => {
  const updateArticleAsync = useAsync<FineArticleWithDefinedValues, any>();
  const [summary, setSummary] = useState<string>("");
  const [summaryEn, setSummaryEn] = useState<string>("");
  const [title, setTitle] = useState<string>("");
  const [text, setText] = useState<string>("");
  const [isShowOtherText, setIsShowOtherText] = useState(false);

  useEffect(() => {
    if (!open) {
      setSummary("");
      setSummaryEn("");
      setTitle("");
      setText("");
      updateArticleAsync.reset();
    }
  }, [open]);

  const handleAddDescription = () => {
    if (open) {
      return updateArticleAsync.run(
        adminFineArticlesService.createOrUpdate(
          new CreateOrUpdateFineArticleModel({
            article: open.article,
            summaryEn,
            summary,
            title,
            text,
          }),
        ),
      );
    }
  };

  useEffect(() => {
    if (updateArticleAsync.data) {
      requestData(updateArticleAsync.data);
      toggleModal();
    }
  }, [updateArticleAsync.data]);

  useEffect(() => {
    if (open) {
      setSummary(open?.summary ?? "");
      setSummaryEn(open?.summaryEn ?? "");
      setTitle(open?.title ?? "");
      setText(open?.text ?? "");
    }
  }, [open]);

  return (
    <Modal
      open={!!open}
      onClose={toggleModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          ...style,
        }}
      >
        <Typography variant="h5" mb={0.5}>
          {open?.article}
        </Typography>
        {open?.externalArticleTexts
          ?.slice(0, isShowOtherText ? undefined : 1)
          ?.map(data => (
            <>
              <Typography variant="h5" mb={1.6}>
                {data?.lawText}
              </Typography>
            </>
          ))}
        {(open?.externalArticleTexts?.length ?? 0) > 1 && (
          <Button onClick={() => setIsShowOtherText(!isShowOtherText)}>
            {isShowOtherText ? "Hide Other Law text" : "Show Other Law text"}
          </Button>
        )}
        <InputLabel
          sx={{
            overflow: "visible !important",
          }}
        >
          შეჯამება (Georgian)
        </InputLabel>
        <div>
          <TextareaAutosize
            value={summary}
            onChange={e => setSummary(e.target.value)}
            style={{
              maxWidth: "100%",
              minWidth: "100%",
              minHeight: 100,
              maxHeight: 200,
              padding: 4,
              marginBottom: 16,
              borderWidth: 1.01,
              borderRadius: 3,
              outlineColor: "#00c6be",
            }}
          />
        </div>

        <InputLabel
          sx={{
            overflow: "visible !important",
          }}
        >
          შეჯამება (English)
        </InputLabel>
        <div>
          <TextareaAutosize
            value={summaryEn}
            onChange={e => setSummaryEn(e.target.value)}
            style={{
              maxWidth: "100%",
              minWidth: "100%",
              minHeight: 100,
              maxHeight: 200,
              padding: 4,
              marginBottom: 16,
              borderWidth: 1.01,
              borderRadius: 3,
              outlineColor: "#00c6be",
            }}
          />
        </div>
        <InputLabel
          sx={{
            overflow: "visible !important",
          }}
        >
          სათაური
        </InputLabel>

        <div style={{ width: "100%" }}>
          <TextareaAutosize
            value={title}
            onChange={e => setTitle(e.target.value)}
            style={{
              maxWidth: "100%",
              minWidth: "100%",
              minHeight: 150,
              maxHeight: 400,
              padding: 4,
              marginBottom: 16,
              borderWidth: 1.01,
              borderRadius: 3,
              outlineColor: "#00c6be",
            }}
          />
        </div>
        <InputLabel
          sx={{
            overflow: "visible !important",
          }}
        >
          ტექსტი
        </InputLabel>

        <div>
          <TextareaAutosize
            value={text}
            onChange={e => setText(e.target.value)}
            style={{
              maxWidth: "100%",
              minWidth: "100%",
              minHeight: 150,
              maxHeight: 400,
              padding: 4,
              marginBottom: 16,
              borderWidth: 1.01,
              borderRadius: 3,
              outlineColor: "#00c6be",
            }}
          />
        </div>
        {updateArticleAsync.isLoading ? (
          <>
            <CircularProgress
              sx={{
                alignSelf: "center",
              }}
            />
          </>
        ) : (
          <Button
            sx={{
              width: "100%",
              mb: 1.6,
            }}
            disabled={updateArticleAsync.isLoading}
            onClick={handleAddDescription}
          >
            {"აღწერის დამატება"}
          </Button>
        )}
        <Button
          sx={{
            width: "100%",
          }}
          onClick={toggleModal}
        >
          Cancel
        </Button>
      </Box>
    </Modal>
  );
};

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "98%",
  display: "flex",
  flexDirection: "column",
  height: "98%",
  minWidth: 1126,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 2,
  overflowY: "auto",
};

export default AddDescriptionModal;
