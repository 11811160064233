import clsx from "clsx";
import styles from "./Discount.module.scss";
import { DiscountChangeRequest } from "services/borbalo-main.service";
import { PreviewLanguage } from "store/locations/slice";

const DiscountRequestChangesPreview = ({
  data,
  language,
}: {
  data: DiscountChangeRequest;
  language: PreviewLanguage;
}) => {
  const isEn = language === "en";

  return (
    <div className={clsx(styles["discount-info"], "row", "gap32")}>
      <div
        className={clsx(styles["discount-section"], "column", "flex1", "mw600")}
      >
        <div className={clsx(styles["discount-active"])}>
          <div className={clsx(styles["discount-active-title"], "mb8")}>
            {"Active Discount"}
          </div>

          <div
            className={clsx(
              styles["discount-active-value"],
              styles["discount-text"],
            )}
          >
            {data?.mainAmount ? `-${data.mainAmount}%` : ""}
          </div>
        </div>
        <div
          className={clsx(styles["discount-category-wrapper"], "medium24-text")}
        >
          {data?.discountAmounts?.map(amount => (
            <div
              key={amount.productNameEn}
              className={clsx(styles["discount-category"], "row-space-between")}
            >
              <div>{isEn ? amount.productNameEn : amount.productNameGe}</div>
              <div>{`-${amount.discountAmount}%`}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default DiscountRequestChangesPreview;
