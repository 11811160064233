import MyTable from "components/MyTable";
import React from "react";

const Home = () => {
  return (
    <>
      <div className="column">WELCOME</div>
      {/* <MyTable /> */}
    </>
  );
};

export default Home;
