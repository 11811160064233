import {
  configureStore,
  combineReducers,
  PayloadAction,
} from "@reduxjs/toolkit";
import authReducer from "store/auth/slice";
import errorReducer from "store/error/slice";
import locationsReducer from "store/locations/slice";

const combinedReducer = combineReducers({
  auth: authReducer,
  error: errorReducer,
  locations: locationsReducer,
});

export const rootReducer = (state: any, action: PayloadAction) => {
  if (action.type === "auth/logout") {
    state = {
      auth: state.auth,
    };
  }
  return combinedReducer(state, action);
};

export const store = configureStore({
  reducer: rootReducer,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
