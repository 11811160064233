import {
  Autocomplete,
  CircularProgress,
  FormControl,
  TextField,
} from "@mui/material";
import useAsync from "hooks/useAsync";
import {
  FineCamera,
  FinesGroupedByCamera,
} from "services/borbalo-main.service";

const Selector = ({
  allLocations,
  defaultValue,
  setValue,
}: {
  allLocations: (FineCamera | undefined)[];
  item?: FinesGroupedByCamera;
  setValue: (value: FineCamera | null | undefined) => void;
  defaultValue: FineCamera | undefined;
  withoutOptionsTitle?: string;
}) => {
  const addLocationToFinesAsync = useAsync<void, any>();

  return (
    <>
      <FormControl fullWidth>
        <Autocomplete
          disablePortal
          id="combo-box-demo"
          options={allLocations}
          sx={{ width: 300 }}
          defaultValue={defaultValue}
          onChange={(_, value) => {
            setValue(value);
          }}
          getOptionLabel={(option: FineCamera | undefined) => {
            return option?.name || "";
          }}
          renderInput={params => (
            <TextField
              {...params}
              inputProps={{
                ...params.inputProps,
              }}
              label=""
            />
          )}
        />
      </FormControl>
      {addLocationToFinesAsync.isLoading && (
        <CircularProgress
          sx={{
            alignSelf: "center",
          }}
        />
      )}
    </>
  );
};

export default Selector;
