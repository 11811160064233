import { CircularProgress, Typography } from "@mui/material";
import MyTable from "components/MyTable";
import useAsync from "hooks/useAsync";
import moment from "moment";
import { useEffect } from "react";
import { adminCarsService } from "pages/InactiveCars/adminCars";
import {
  InactiveCarsPage,
  PagedListOfInactiveCarViewModel,
} from "services/borbalo-main.service";

const InactiveCars = ({
  page,
  title,
}: {
  page: InactiveCarsPage;
  title: string;
}) => {
  const { data, isLoading, run } = useAsync<
    PagedListOfInactiveCarViewModel,
    any
  >();

  const inactiveContactedAsync = useAsync();

  useEffect(() => {
    run(adminCarsService.inactive(page));
  }, [page]);

  const dataInfo =
    data?.entities?.map(item => [
      item.createdAt
        ? moment(item.createdAt).format("DD.MM.YYYY HH:mm:ss")
        : "",
      item.userName,
      item.phoneNumber,
      item.carRegistration,
      item.licencePlate,
    ]) ?? [];

  const tableData = [
    ["Date added", "User name", "Phone", "Registration", "License plate"],
    ...dataInfo,
  ];

  const onPress = (index: number) => {
    const entity = data?.entities?.[index];
    if (entity) {
      inactiveContactedAsync
        .run(
          adminCarsService.inactiveContacted(
            entity.carRegistration!,
            entity.userId!,
            page,
          ),
        )
        .then(() => {
          run(adminCarsService.inactive(page));
        });
    }
  };

  return (
    <>
      <Typography variant="h3" mb={1.6}>
        {title}
        {isLoading && (
          <CircularProgress
            sx={{
              alignSelf: "center",
              marginLeft: 3.6,
            }}
          />
        )}
      </Typography>
      <Typography variant="h4" mb={1.6}>
        Total {data?.total}
      </Typography>
      <div className="column"></div>
      <MyTable tableData={tableData} onPress={onPress} />
    </>
  );
};

export default InactiveCars;
