import { CircularProgress, Typography } from "@mui/material";
import MyTable from "components/MyTable";
import useAsync from "hooks/useAsync";
import { useEffect } from "react";
import { adminUserFeedBackService } from "services/adminUserFeedBackService";
import { PeopleWithCardProblemsViewModel } from "services/borbalo-main.service";

const RegisterCardIssues = () => {
  const { data, isLoading, run } = useAsync<
    PeopleWithCardProblemsViewModel,
    any
  >();

  const blockedContactedAsync = useAsync();

  useEffect(() => {
    run(adminUserFeedBackService.registerCardFeedbacks());
  }, []);

  const dataInfo =
    data?.agreedToContact?.entities?.map(item => [
      item.userName,
      item.phoneNumber,
    ]) ?? [];

  const tableData = [["User name", "Phone"], ...dataInfo];

  const onPress = (index: number) => {
    const entity = data?.agreedToContact?.entities?.[index];
    if (entity) {
      blockedContactedAsync
        .run(
          adminUserFeedBackService.registerCardFeedbackContacted(
            entity.userId!,
          ),
        )
        .then(() => {
          run(adminUserFeedBackService.registerCardFeedbacks());
        });
    }
  };

  return (
    <>
      <Typography variant="h3" mb={3.6}>
        Register card issues
        {isLoading && (
          <CircularProgress
            sx={{
              alignSelf: "center",
              marginLeft: 3.6,
            }}
          />
        )}
      </Typography>
      <Typography variant="h5" mb={1.6}>
        Don't wish to add a card: {data?.dontWishToAddCard}
      </Typography>
      <Typography variant="h5" mb={3.6}>
        Declined to contact: {data?.declinedToContact}
      </Typography>
      <Typography variant="h4" mb={1.6}>
        Agreed to contact: {data?.agreedToContact?.total}
      </Typography>
      <div className="column"></div>
      <MyTable tableData={tableData} onPress={onPress} />
    </>
  );
};

export default RegisterCardIssues;
