import React, { useEffect } from "react";
import { Box, Button } from "@mui/material";
import useAsync from "hooks/useAsync";
import { adminFinesService } from "services/fines";
import { AddOrUpdateCamerasRouteRequest } from "services/borbalo-main.service";

const ChangeRouteSection = ({
  resetRoute,
  isNewRouteChanged,
  newRoute,
  groupKey,
  changeRoute,
  handleClose,
}: {
  resetRoute: () => void;
  isNewRouteChanged: boolean;
  newRoute: number[][];
  groupKey: string;
  changeRoute?: (newRouteLonLat: number[][]) => void;
  handleClose: () => void;
}) => {
  const saveNewRouteAsync = useAsync<void, any>();

  const isLoading = saveNewRouteAsync.isLoading;

  const saveNewRoute = () => {
    saveNewRouteAsync.run(
      adminFinesService.addOrUpdateCamerasRoute(
        new AddOrUpdateCamerasRouteRequest({
          groupKey,
          coordinates: newRoute.map(item => `${item[1]},${item[0]}`),
        }),
      ),
    );
  };

  useEffect(() => {
    if (saveNewRouteAsync.isSuccess) {
      changeRoute?.(newRoute);
    }
  }, [saveNewRouteAsync.isSuccess]);

  return (
    <>
      <Box
        sx={{
          gap: 3,
          marginBottom: 0,
          background: "#fff",
          padding: 2,
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-around",
          flexWrap: "wrap",
        }}
      >
        <Button onClick={resetRoute} disabled={!isNewRouteChanged}>
          Reset to Generated Route
        </Button>
        <Button
          onClick={saveNewRoute}
          disabled={!isNewRouteChanged || isLoading}
        >
          Save Route
        </Button>
        <Button onClick={handleClose}>Cancel</Button>
      </Box>
    </>
  );
};

export default ChangeRouteSection;
