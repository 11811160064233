import { ReactNode } from "react";

export const SvgIcon = ({
  children,
  className,
  size = 24,
  viewBox = "0 0 24 24",
}: {
  children?: ReactNode;
  className?: string;
  size?: number;
  viewBox?: string;
}) => {
  return (
    <svg
      className={className}
      fill="none"
      width={size}
      height={size}
      viewBox={viewBox}
    >
      {children}
    </svg>
  );
};
