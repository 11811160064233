import { AdBanner } from "services/borbalo-main.service";
import { IconArrowRight } from "./assets/IconArrowRight";
import "./styles.css";

const LargeBannerPreview = ({
  data,
  images,
  isGe,
}: {
  data: AdBanner;
  images: { [id: string]: string }[];
  isGe?: boolean;
}) => {
  const color = data.metadata?.textColor ?? "#000";
  const image = images.find(item => !!item[data.id])?.[data.id];

  const texts = isGe
    ? {
        title: data.metadata?.titleCaptionGe,
        callToAction: data.metadata?.callToActionCaptionGe,
      }
    : {
        title: data.metadata?.titleCaptionEn,
        callToAction: data.metadata?.callToActionCaptionEn,
      };

  return (
    <div>
      <div
        className={"large-banner-wrapper"}
        style={{
          backgroundColor: data.metadata?.backgroundColor || "#fff",
        }}
      >
        <div
          style={{
            height: 162,
          }}
        >
          {image && (
            <img src={image} alt="large banner" width={"auto"} height={162} />
          )}
        </div>
        <div className={"lagre-banner-text-wrapper"}>
          <div
            className="medium16-text color-black"
            style={{
              maxHeight: 38,
              overflow: "hidden",
              overflowWrap: "anywhere",
              color,
              marginBottom: 8,
            }}
          >
            {texts.title}
          </div>

          <div className="row-align-center">
            <div
              className="regular13-text color-black"
              style={{
                maxHeight: 17,
                overflow: "hidden",
                color,
              }}
            >
              {texts.callToAction}
              &nbsp;&nbsp;
            </div>
            <IconArrowRight size={11} fill={color} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default LargeBannerPreview;
