export const IconDiscountBannerGradient = ({ fill }: { fill: string }) => {
  const id = "radial-gradient" + fill;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="343"
      height="120"
      viewBox="0 0 343 120"
    >
      <defs>
        <radialGradient
          id={id}
          cx="0.026"
          cy="1"
          r="0.945"
          gradientTransform="matrix(0.995, 0.102, -0.142, 1.382, 0.142, -0.385)"
          gradientUnits="objectBoundingBox"
        >
          <stop offset="0" stop-color={fill} />
          <stop offset="1" stop-color="#fff" stopOpacity="1" />
        </radialGradient>
      </defs>
      <rect
        id={"Brand_Gradient"}
        data-name="Brand Gradient"
        width="343"
        height="120"
        rx="10"
        fill={`url(#${id})`}
      />
    </svg>
  );
};
