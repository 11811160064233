import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AuthStateType } from "./types";

const initialState: AuthStateType = {
  isUserSignedIn: undefined,
  roles: [],
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setIsUserSingedIn: (
      state,
      { payload }: PayloadAction<boolean | undefined>,
    ) => {
      state.isUserSignedIn = payload;
    },
    login: state => {
      state.isUserSignedIn = true;
    },
    logout: state => {
      state.isUserSignedIn = false;
      state.roles = [];
    },
    setRoles: (state, { payload }: PayloadAction<string[]>) => {
      state.roles = payload;
    },
  },
});

export const { login, logout, setIsUserSingedIn, setRoles } = authSlice.actions;

export default authSlice.reducer;
