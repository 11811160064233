import { Box } from "@mui/material";
import { AdState, DiscountProgramState } from "services/borbalo-main.service";
import { IconSquare } from "./assets/IconSquare";

const colorByState = {
  [AdState.Active]: {
    color: "main",
    text: "Active",
  },
  [AdState.Deactivated]: {
    color: "grey",
    text: "Deactivated",
  },
  [AdState.OnModeration]: {
    color: "yellow",
    text: "On moderation",
  },
  [DiscountProgramState.InProgress]: {
    color: "grey",
    text: "In Progress",
  },
};

const StateComponent = ({
  state,
  marginBottom,
  count,
}: {
  state: AdState | DiscountProgramState;
  marginBottom?: number;
  count?: number;
}) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        minWidth: 150,
        marginBottom,
      }}
    >
      <IconSquare
        className={`color-${colorByState[state].color} shrink0`}
        size={20}
      />
      &nbsp;&nbsp;
      {colorByState[state].text}
      {!!count && ` (${count})`}
    </Box>
  );
};

export default StateComponent;
