import { CircularProgress, Typography } from "@mui/material";
import MyTable from "components/MyTable";
import useAsync from "hooks/useAsync";
import { useEffect } from "react";
import { PagedListOfAdsProvider } from "services/borbalo-main.service";
import { adminAdsService } from "services/adminAds";
import { useNavigate } from "react-router-dom";

const AdsProviders = ({ title }: { title: string }) => {
  const navigate = useNavigate();
  const { data, isLoading, run } = useAsync<PagedListOfAdsProvider, any>();

  useEffect(() => {
    run(adminAdsService.getAllAdsProviders());
  }, []);

  const dataInfo =
    data?.entities?.map(item => [item.name, item.contacts]) ?? [];

  const tableData = [["Name", "Contacts"], ...dataInfo];

  const onPress = (index: number) => {
    const entity = data?.entities?.[index];
    if (entity?.id) {
      navigate(`/ads-providers/${entity.id}`);
    }
  };

  return (
    <>
      <Typography variant="h3" mb={1.6}>
        {title}
        {isLoading && (
          <CircularProgress
            sx={{
              alignSelf: "center",
              marginLeft: 3.6,
            }}
          />
        )}
      </Typography>
      <Typography variant="h4" mb={1.6}>
        Total {data?.total}
      </Typography>
      <div className="column"></div>
      <MyTable
        tableData={tableData}
        onPress={onPress}
        buttonText={"Show Ads"}
      />
    </>
  );
};

export default AdsProviders;
