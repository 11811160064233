import { createSelector } from "reselect";
import type { RootState } from "store";

const locationsState = (state: RootState) => state.locations;

export const locationsSelector = createSelector(
  [locationsState],
  ({ locations }) => locations,
);

export const goodToKnowCategoriesSelector = createSelector(
  [locationsState],
  ({ goodToKnowCategories }) => goodToKnowCategories,
);

export const previewLanguageSelector = createSelector(
  [locationsState],
  ({ previewLanguage }) => previewLanguage,
);

export const currentBrandSelector = createSelector(
  [locationsState],
  ({ currentBrand }) => currentBrand,
);
