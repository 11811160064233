import {
  Box,
  Button,
  CircularProgress,
  FormControlLabel,
  Paper,
  Radio,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import Selector from "./Selector";
import { useNavigate } from "react-router-dom";
import useAsync from "hooks/useAsync";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { adminFinesService } from "services/fines";
import {
  AddCameraToFinesRequest,
  FineCamera,
  PagedListOfFineCamera,
  FinesGroupedByCamera,
  PagedListOfFinesGroupedByCamera,
  FineCameraPositionType,
  FineCameraGroupInfo,
} from "services/borbalo-main.service";
import EditIcon from "@mui/icons-material/Edit";
import AddNewLocationModal from "components/AddNewLocationModal";
import SaveIcon from "@mui/icons-material/Save";
import Viewer from "./Viewer";
import { IconChevronDown } from "pages/Fines/assets/IconChevron";
import clsx from "clsx";
import uniqueBy from "lodash.uniqby";
import { useSearch } from "components/SearchComponent/useSearch";
import MergeModal from "pages/Fines/MergeModal";
import DeleteIcon from "@mui/icons-material/Delete";
import DeleteModal from "pages/Fines/DeleteModal";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  text: {
    display: "flex",
    width: "50%",
    justifyContent: "center",
  },
  tableCell: {
    maxWidth: 200,
    borderStyle: "border-box",
  },
  withoutBorder: {
    borderBottomWidth: "0 !important",
  },
  group: { top: -50, position: "relative" },
});

const header: {
  title: string;
  field?: keyof FinesGroupedByCamera | undefined;
}[] = [
  { title: "Group Id" },
  { title: "Number of fines", field: "finesCount" },
  { title: "Pictures" },
  { title: "Region", field: "region" },
  { title: "Municipality", field: "municipality" },
  { title: "Location From Image en", field: "locationFromImageLatin" },
  { title: "Location From Image ge", field: "locationFromImageTranscription" },
  { title: "Corrected Location" },
];

const round = (value: number, fractionDigits = 2) => {
  const multiplier = Math.pow(10, fractionDigits);
  return Math.ceil(value * multiplier) / multiplier;
};

const DEFAULT_COUNT = 100000;

const PAGINATION_LIMIT = 500;

const Fines = () => {
  const [itemsForMerge, setItemsForMerge] = useState<FinesGroupedByCamera[]>(
    [],
  );
  const [isMergeModalOpen, setIsMergeModalOpen] = useState(false);
  const openMergeModal = () => {
    setIsMergeModalOpen(true);
  };

  const closeMergeModal = () => {
    setIsMergeModalOpen(false);
  };
  // IMAGES
  const [isViewerOpen, setIsViewerOpen] = useState(-1);
  const openImageViewer = useCallback((index: number) => {
    setIsViewerOpen(index);
  }, []);
  const closeImageViewer = () => {
    setIsViewerOpen(-1);
  };

  const lastRowRef = useRef(null);
  const getFinesAsync = useAsync<PagedListOfFinesGroupedByCamera, any>();
  const navigate = useNavigate();
  const classes = useStyles();
  const allLocationsAsync = useAsync<PagedListOfFineCamera, any>();
  const [allLocations, setAllLocations] = useState<FineCamera[]>([]);
  const [finesData, setFinesData] = useState<FinesGroupedByCamera[]>([]);
  const [count, setCount] = useState(PAGINATION_LIMIT);
  const [deleteModalOpen, setDeleteModalOpen] = useState<
    FinesGroupedByCamera | undefined
  >();

  const [editIndex, setEditIndex] = useState<number | undefined>();
  const [selectorValues, setSelectorValues] = useState<{
    [index: number]: FineCamera | null | undefined;
  }>([]);
  const addLocationToFinesAsync = useAsync<void, any>();
  const { search, SearchComponent } = useSearch();

  const [open, setOpen] = useState<FinesGroupedByCamera | undefined>();
  const handleOpen = (item: FinesGroupedByCamera) => setOpen(item);

  const requestFines = (callback?: () => void) => {
    getFinesAsync.reset();
    getFinesAsync
      .run(adminFinesService.groupedByCamera(1, DEFAULT_COUNT))
      .finally(callback);
  };

  const removeFine = (item: FinesGroupedByCamera) => {
    setFinesData(s =>
      s.filter(
        i =>
          !(
            i.locationFromImageLatin === item.locationFromImageLatin &&
            i.region === item.region &&
            i.municipality === item.municipality
          ),
      ),
    );
  };

  useEffect(() => {
    getFinesAsync.run(
      adminFinesService.groupedByCamera(
        round(finesData.length / DEFAULT_COUNT, 0) + 1,
        DEFAULT_COUNT,
      ),
    );
  }, []);

  useEffect(() => {
    const data = getFinesAsync.data?.entities;
    if (data) {
      setFinesData(data);
    }
  }, [getFinesAsync.data]);

  useEffect(() => {
    allLocationsAsync.run(adminFinesService.allCameras(1, 10000));
  }, []);

  useEffect(() => {
    if (allLocationsAsync.data)
      setAllLocations(allLocationsAsync.data?.entities ?? []);
  }, [allLocationsAsync.data]);

  const handleAddLocationToFine = (
    cameraItem: FinesGroupedByCamera,
    index?: number,
    newLocation?: FineCamera,
  ) => {
    const filteredFines = finesData.filter(
      item =>
        item.region === cameraItem.region &&
        item.municipality === cameraItem.municipality &&
        item.locationFromImageLatin === cameraItem.locationFromImageLatin,
    );

    addLocationToFinesAsync
      .run(
        adminFinesService.addCameraToFines(
          new AddCameraToFinesRequest({
            locationFromImageLatin: cameraItem.locationFromImageLatin!,
            cameraId:
              newLocation?.id ??
              (index !== undefined
                ? selectorValues[index]?.id ?? undefined
                : undefined),
            region: cameraItem.region!,
            municipality: cameraItem.municipality!,

            groups: filteredFines.map(
              item =>
                new FineCameraGroupInfo({
                  groupKey: item.cameraGroupId,
                  positionType:
                    item.positionType ?? FineCameraPositionType.First,
                }),
            ),
          }),
        ),
      )
      .then(e => {
        if (!newLocation) {
          const correctedLocation = selectorValues?.[index ?? -1];
          setFinesData(s =>
            s.map(item =>
              item.locationFromImageLatin ===
                cameraItem.locationFromImageLatin &&
              item.region === cameraItem.region &&
              item.municipality === cameraItem.municipality
                ? new FinesGroupedByCamera({
                    ...item,
                    camera: correctedLocation ?? undefined,
                  })
                : item,
            ),
          );
          if (index !== undefined) {
            selectorValues[index] = null;
          }
          setEditIndex(undefined);
        } else if (newLocation) {
          setFinesData(s =>
            s.map(item =>
              item.locationFromImageLatin ===
                cameraItem.locationFromImageLatin &&
              item.region === cameraItem.region &&
              item.municipality === cameraItem.municipality
                ? new FinesGroupedByCamera({
                    ...item,
                    camera: newLocation ?? undefined,
                  })
                : item,
            ),
          );
          setAllLocations(s => [newLocation, ...s]);
          setOpen(undefined);
        }
      });
  };
  const [isShowAll, setIsShowAll] = useState(true);
  const [activeKey, setActiveKey] = useState("");

  const [sortData, setSortData] = useState<{
    sortIndex: number;
    sortType: string;
    sortField: keyof FinesGroupedByCamera;
  }>({
    sortIndex: 1,
    sortType: "desc",
    sortField: "finesCount",
  });

  const onHeaderClick = (
    index: number,
    sortField: keyof FinesGroupedByCamera | undefined,
  ) => {
    if (!sortField) {
      return;
    }

    setSortData(prevState => {
      if (prevState.sortIndex === index) {
        return {
          sortIndex: index,
          sortType: prevState.sortType === "asc" ? "desc" : "asc",
          sortField,
        };
      }

      return {
        sortIndex: index,
        sortType: "desc",
        sortField,
      };
    });
  };

  const sortedFinesData1 = useMemo(() => {
    if (sortData.sortField !== "finesCount" || search) {
      return uniqueBy(
        finesData,
        (item: FinesGroupedByCamera) => item.locationFromImageLatin,
      )
        .filter(
          item =>
            item.locationFromImageLatin
              ?.toLowerCase()
              .includes(search.toLowerCase()) ||
            item.locationFromImageTranscription
              ?.toLowerCase()
              .includes(search.toLowerCase()) ||
            item.region?.toLowerCase().includes(search.toLowerCase()) ||
            item.camera?.name?.toLowerCase().includes(search.toLowerCase()) ||
            item.municipality?.toLowerCase().includes(search.toLowerCase()),
        )
        .map(
          item =>
            new FinesGroupedByCamera({
              ...item,
              finesCount: finesData.reduce((acc, val) => {
                if (
                  val.locationFromImageLatin === item.locationFromImageLatin &&
                  val.region === item.region &&
                  val.municipality === item.municipality
                ) {
                  return acc + val.finesCount;
                }
                return acc;
              }, 0),
            }),
        )
        .sort((a, b) => {
          const sortCondition =
            sortData.sortType === "desc"
              ? a[sortData.sortField]! < b[sortData.sortField]!
              : a[sortData.sortField]! > b[sortData.sortField]!;
          return sortCondition ? 1 : -1;
        });
    } else {
      return finesData
        .slice()
        .sort((a, b) => {
          const condition = a.positionType! < b.positionType!;
          return condition ? 1 : -1;
        })
        .sort((a, b) => {
          const sortCondition =
            sortData.sortType === "desc"
              ? a.cameraGroupId! < b.cameraGroupId!
              : a.cameraGroupId! > b.cameraGroupId!;
          return sortCondition ? 1 : -1;
        })
        .sort((a, b) => {
          const sortCondition =
            sortData.sortType === "desc"
              ? b.finesCount - a.finesCount
              : a.finesCount - b.finesCount;

          return sortCondition;
        });
    }
  }, [finesData, sortData, search]);

  const sortedFinesData = useMemo(() => {
    return sortedFinesData1.filter((_, index) => index < count);
  }, [sortedFinesData1, count]);

  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => {
      if (entry.isIntersecting) {
        setCount(s => s + PAGINATION_LIMIT);
      }
    });
    if (lastRowRef.current) {
      observer.observe(lastRowRef.current);
    }
    return () => {
      observer.disconnect();
    };
  }, [finesData.length, count, sortData]);

  useEffect(() => {
    setCount(PAGINATION_LIMIT);
  }, [sortData, isShowAll]);

  const grouppedInfo = useMemo(() => {
    const ids = sortedFinesData
      .filter(({ camera }) => (isShowAll ? true : !camera))
      .map(({ cameraGroupId }) => cameraGroupId);

    const filteredIds = ids.filter((str, index, arr) => {
      const count = arr.reduce((acc, val) => (val === str ? acc + 1 : acc), 0);

      return count === 2;
    });

    const filteredIndexes = filteredIds.map(id =>
      sortedFinesData.findIndex(({ cameraGroupId }) => cameraGroupId === id),
    );
    const s = [...new Set(filteredIndexes)];

    return { filteredIds, filteredIndexes: s };
  }, [sortedFinesData, isShowAll]);

  return (
    <>
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Typography variant="h3" mb={1.6}>
          Fines
          {(getFinesAsync.isLoading || allLocationsAsync.isLoading) && (
            <CircularProgress
              sx={{
                alignSelf: "center",
                marginLeft: 3.6,
              }}
            />
          )}
        </Typography>
        <Button onClick={() => navigate("/fines-locations")}>
          List of Cameras
        </Button>
      </Box>
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Typography variant="h4" mb={1.6}>
          Total {sortData.sortField === "finesCount" ? "in Groupes" : ""}{" "}
          {sortedFinesData1.length} / Corrected{" "}
          {sortedFinesData1.filter(({ camera }) => !!camera).length}
        </Typography>
      </Box>
      <Box sx={{ display: "flex", justifyContent: "space-between", mb: 2 }}>
        {SearchComponent}
        <Button onClick={() => setIsShowAll(s => !s)}>
          {isShowAll
            ? "Hide fines with corrected Cameras"
            : "Show fines with corrected Cameras"}
        </Button>
      </Box>
      <TableContainer
        component={Paper}
        style={{ marginBottom: 400, overflow: "visible" }}
      >
        <Table className={classes.table} size="small" aria-label="my table">
          <TableHead>
            <TableRow>
              {header.map((headerItem, index) => (
                <TableCell
                  onClick={() => onHeaderClick(index, headerItem.field)}
                  key={headerItem.title}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      cursor: "pointer",
                      color: !headerItem.field ? "grey" : undefined,
                    }}
                  >
                    {headerItem.title}

                    <Box
                      sx={{
                        width: 16,
                        height: 16,
                        marginLeft: 0.8,
                        alignItems: "center",
                      }}
                    >
                      {sortData.sortIndex === index && (
                        <IconChevronDown
                          size={16}
                          className={
                            (clsx("color-grey"),
                            sortData.sortType === "asc" ? "flip" : undefined)
                          }
                        />
                      )}
                    </Box>
                  </Box>
                </TableCell>
              ))}
              <TableCell></TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedFinesData.map((item, index) => (
              <React.Fragment
                key={
                  item.cameraGroupId +
                  item.locationFromImageLatin +
                  item.cameraGroupId +
                  item.locationFromImageTranscription +
                  index
                }
              >
                <div
                  ref={
                    !getFinesAsync.isLoading &&
                    sortedFinesData.length <
                      Number(sortedFinesData1.length ?? 0) &&
                    index === sortedFinesData.length - 1
                      ? lastRowRef
                      : undefined
                  }
                ></div>
                <TableRow
                  onClick={() => setActiveKey(item.locationFromImageLatin)}
                  style={{
                    display: !isShowAll && item.camera ? "none" : "table-row",
                    backgroundColor:
                      item.locationFromImageLatin === activeKey
                        ? "rgba(0, 198, 190, 0.1)"
                        : "white",
                  }}
                >
                  {sortData.sortField !== "finesCount" ||
                  grouppedInfo.filteredIndexes.includes(index) ? (
                    <TableCell
                      className={
                        sortData.sortField === "finesCount"
                          ? classes.withoutBorder
                          : undefined
                      }
                      component="th"
                      scope="row"
                    >
                      {sortData.sortField === "finesCount"
                        ? ""
                        : item.cameraGroupId.slice(0, 8)}
                    </TableCell>
                  ) : (
                    <TableCell component="th" scope="row">
                      <div
                        className={
                          grouppedInfo.filteredIds.includes(item.cameraGroupId)
                            ? classes.group
                            : undefined
                        }
                      >
                        {item.cameraGroupId.slice(0, 8)}
                      </div>
                    </TableCell>
                  )}
                  {sortData.sortField !== "finesCount" ||
                  grouppedInfo.filteredIndexes.includes(index) ? (
                    <TableCell
                      className={
                        sortData.sortField === "finesCount"
                          ? classes.withoutBorder
                          : undefined
                      }
                      component="th"
                      scope="row"
                    >
                      {sortData.sortField === "finesCount"
                        ? ""
                        : item.finesCount}
                    </TableCell>
                  ) : (
                    <TableCell component="th" scope="row">
                      <div
                        className={
                          grouppedInfo.filteredIds.includes(item.cameraGroupId)
                            ? classes.group
                            : undefined
                        }
                      >
                        {item.finesCount}
                      </div>
                    </TableCell>
                  )}
                  <TableCell component="th" scope="row">
                    <Button onClick={() => openImageViewer(index)}>
                      Show media
                    </Button>
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {item.region}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {item.municipality}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {sortData.sortField === "finesCount" ? (
                      item.locationFromImageLatin
                    ) : (
                      <>
                        <FormControlLabel
                          value={item}
                          control={
                            <Radio
                              checked={
                                !!itemsForMerge.find(
                                  ({ locationFromImageLatin, cameraGroupId }) =>
                                    locationFromImageLatin ===
                                      item.locationFromImageLatin &&
                                    cameraGroupId === item.cameraGroupId,
                                )
                              }
                              // TODO FIX
                              disabled={
                                (!itemsForMerge
                                  .map(value => value.locationFromImageLatin)
                                  .includes(item.locationFromImageLatin) &&
                                  finesData
                                    .filter(item =>
                                      itemsForMerge
                                        .map(
                                          ({ locationFromImageLatin }) =>
                                            locationFromImageLatin,
                                        )
                                        .some(
                                          locationFromImageLatin =>
                                            locationFromImageLatin ===
                                            item.locationFromImageLatin,
                                        ),
                                    )
                                    .map(item => item.cameraGroupId)
                                    .some(data =>
                                      finesData
                                        .filter(
                                          ({ locationFromImageLatin }) =>
                                            locationFromImageLatin ===
                                            item.locationFromImageLatin,
                                        )
                                        .map(item => item.cameraGroupId)
                                        .includes(data),
                                    )) ||
                                (itemsForMerge.length !== 0 &&
                                  (itemsForMerge[0]?.region !== item.region ||
                                    itemsForMerge[0]?.municipality !==
                                      item.municipality))
                              }
                            />
                          }
                          onMouseDown={() => {
                            if (
                              (!itemsForMerge
                                .map(value => value.locationFromImageLatin)
                                .includes(item.locationFromImageLatin) &&
                                finesData
                                  .filter(item =>
                                    itemsForMerge
                                      .map(
                                        ({ locationFromImageLatin }) =>
                                          locationFromImageLatin,
                                      )
                                      .some(
                                        locationFromImageLatin =>
                                          locationFromImageLatin ===
                                          item.locationFromImageLatin,
                                      ),
                                  )
                                  .map(item => item.cameraGroupId)
                                  .some(data =>
                                    finesData
                                      .filter(
                                        ({ locationFromImageLatin }) =>
                                          locationFromImageLatin ===
                                          item.locationFromImageLatin,
                                      )
                                      .map(item => item.cameraGroupId)
                                      .includes(data),
                                  )) ||
                              (itemsForMerge.length !== 0 &&
                                (itemsForMerge[0]?.region !== item.region ||
                                  itemsForMerge[0]?.municipality !==
                                    item.municipality))
                            ) {
                              return;
                            }
                            setItemsForMerge(prState => {
                              if (
                                prState.find(
                                  ({ locationFromImageLatin, cameraGroupId }) =>
                                    locationFromImageLatin ===
                                      item.locationFromImageLatin &&
                                    cameraGroupId === item.cameraGroupId,
                                )
                              ) {
                                return prState.filter(
                                  ({ locationFromImageLatin, cameraGroupId }) =>
                                    locationFromImageLatin !==
                                      item.locationFromImageLatin ||
                                    cameraGroupId !== item.cameraGroupId,
                                );
                              } else {
                                return [...prState, item];
                              }
                            });
                          }}
                          label={item.locationFromImageLatin}
                        />
                        {itemsForMerge.length > 1 &&
                          itemsForMerge[itemsForMerge.length - 1]
                            ?.locationFromImageLatin ===
                            item.locationFromImageLatin &&
                          itemsForMerge[itemsForMerge.length - 1]
                            ?.cameraGroupId === item.cameraGroupId && (
                            <Button onClick={openMergeModal}>Rename</Button>
                          )}
                      </>
                    )}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {item.locationFromImageTranscription}
                  </TableCell>
                  <TableCell>
                    {allLocations?.length && (
                      <>
                        {item.camera && editIndex !== index ? (
                          item.camera.name
                        ) : (
                          <Selector
                            setValue={(value: FineCamera | null | undefined) =>
                              setSelectorValues(s => ({
                                ...s,
                                [index]: value,
                              }))
                            }
                            defaultValue={item.camera}
                            allLocations={allLocations}
                            item={item}
                          />
                        )}
                      </>
                    )}
                  </TableCell>
                  <TableCell>
                    {(editIndex === index || selectorValues[index]?.id) && (
                      <Button
                        disabled={addLocationToFinesAsync.isLoading}
                        onClick={() => handleAddLocationToFine(item, index)}
                      >
                        <SaveIcon />
                      </Button>
                    )}
                    {editIndex !== index && !selectorValues[index]?.id && (
                      <>
                        {item.camera ? (
                          <Button
                            onClick={() => {
                              setEditIndex(index);
                              setSelectorValues(s => ({
                                ...s,
                                [index]: item.camera!,
                              }));
                            }}
                          >
                            <EditIcon />
                          </Button>
                        ) : (
                          <Button
                            onClick={() => {
                              handleOpen(item);
                            }}
                          >
                            Add new location
                          </Button>
                        )}
                      </>
                    )}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    <Button
                      onClick={() => {
                        setDeleteModalOpen(item);
                      }}
                    >
                      <DeleteIcon />
                    </Button>
                  </TableCell>
                  {index === isViewerOpen && (
                    <Viewer
                      images={item.images}
                      closeImageViewer={closeImageViewer}
                    />
                  )}
                </TableRow>
              </React.Fragment>
            ))}
          </TableBody>
        </Table>
        {(!getFinesAsync.data || count < (sortedFinesData1?.length ?? 0)) && (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
            }}
          >
            <CircularProgress
              sx={{
                alignSelf: "center",
                marginTop: 2,
                marginBottom: 2,
              }}
            />
          </div>
        )}
      </TableContainer>
      {isMergeModalOpen && (
        <MergeModal
          items={itemsForMerge}
          closeImageViewer={closeMergeModal}
          resetItemsForMerge={() => setItemsForMerge([])}
          header={header}
          classes={classes}
          requestFines={requestFines}
        />
      )}
      <AddNewLocationModal
        open={!!open}
        address={open?.locationFromImageLatin}
        recognizedMaxSpeed={open?.recognizedMaxSpeed}
        itemRegion={open?.region}
        itemMunicipality={open?.municipality}
        images={open?.images}
        handleClose={() => setOpen(undefined)}
        onSave={(newLocation: FineCamera) => {
          if (open) {
            handleAddLocationToFine(open, undefined, newLocation);
          }
        }}
      />
      <DeleteModal
        open={deleteModalOpen}
        toggleModal={() => setDeleteModalOpen(undefined)}
        requestData={removeFine}
      />
    </>
  );
};

export default React.memo(Fines);
