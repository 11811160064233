import { CircularProgress, Typography } from "@mui/material";
import MyTable from "components/MyTable";
import useAsync from "hooks/useAsync";
import { useEffect } from "react";
import { adminCarsService } from "pages/InactiveCars/adminCars";
import { PagedListOfBlockedCarViewModel } from "services/borbalo-main.service";

const BlockedCars = () => {
  const { data, isLoading, run } = useAsync<
    PagedListOfBlockedCarViewModel,
    any
  >();

  const blockedContactedAsync = useAsync();

  useEffect(() => {
    run(adminCarsService.blocked());
  }, []);

  const dataInfo =
    data?.entities?.map(item => [
      item.userName,
      item.phoneNumber,
      item.carRegistration,
      item.licencePlate,
    ]) ?? [];

  const tableData = [
    ["User name", "Phone", "Registration", "License plate"],
    ...dataInfo,
  ];

  const onPress = (index: number) => {
    const entity = data?.entities?.[index];
    if (entity) {
      blockedContactedAsync
        .run(
          adminCarsService.blockedContacted(
            entity.carRegistration!,
            entity.userId!,
          ),
        )
        .then(() => {
          run(adminCarsService.blocked());
        });
    }
  };

  return (
    <>
      <Typography variant="h3" mb={1.6}>
        Blocked cars
        {isLoading && (
          <CircularProgress
            sx={{
              alignSelf: "center",
              marginLeft: 3.6,
            }}
          />
        )}
      </Typography>
      <Typography variant="h4" mb={1.6}>
        Total {data?.total}
      </Typography>
      <div className="column"></div>
      <MyTable tableData={tableData} onPress={onPress} />
    </>
  );
};

export default BlockedCars;
