import { CircularProgress, Typography } from "@mui/material";
import MyTable from "components/MyTable";
import useAsync from "hooks/useAsync";
import moment from "moment";
import { useEffect } from "react";
import { adminUserFeedBackService } from "services/adminUserFeedBackService";
import { PagedListOfSupportCallRequestViewModel } from "services/borbalo-main.service";

const SupportCallRequests = () => {
  const { data, isLoading, run } = useAsync<
    PagedListOfSupportCallRequestViewModel,
    any
  >();

  const blockedContactedAsync = useAsync();

  useEffect(() => {
    run(adminUserFeedBackService.supportCallRequests());
  }, []);

  const dataInfo =
    data?.entities?.map(item => [
      item.id?.toString(),
      item.createdAt
        ? moment(item.createdAt).format("DD.MM.YYYY HH:mm:ss")
        : "",
      item.phoneNumber,
      item.paidAmount?.toString(),
      item.paymentType,
      item.entityId?.toString(),
    ]) ?? [];

  const tableData = [
    [
      "Id",
      "Created At",
      "Phone Number",
      "Paid amount (₾)",
      "Payment Type",
      "Payment Id",
    ],
    ...dataInfo,
  ];

  const onPress = (index: number) => {
    const entity = data?.entities?.[index];
    if (entity) {
      blockedContactedAsync
        .run(adminUserFeedBackService.resolveSupportCallRequest(entity.id!))
        .then(() => {
          run(adminUserFeedBackService.supportCallRequests());
        });
    }
  };

  return (
    <>
      <Typography variant="h3" mb={1.6}>
        Support call requests
        {isLoading && (
          <CircularProgress
            sx={{
              alignSelf: "center",
              marginLeft: 3.6,
            }}
          />
        )}
      </Typography>
      <Typography variant="h4" mb={1.6}>
        Total {data?.total}
      </Typography>
      <div className="column"></div>
      <MyTable tableData={tableData} onPress={onPress} buttonText={"Resolve"} />
    </>
  );
};

export default SupportCallRequests;
