import { Box, Button, CircularProgress, Typography } from "@mui/material";
import MyTable from "components/MyTable";
import useAsync from "hooks/useAsync";
import { useEffect, useMemo, useState } from "react";
import EditIcon from "@mui/icons-material/Edit";
import {
  FineArticleState,
  FineArticleWithDefinedValues,
  PagedListOfFineArticleWithDefinedValues,
} from "services/borbalo-main.service";
import { adminFineArticlesService } from "services/adminFineArticles";
import AddDescriptionModal from "pages/Articles/AddDescriptionModal";
import { userRolesSelector } from "store/auth/selectors";
import { useSelector } from "react-redux";

const Articles = ({ title }: { title: string }) => {
  const userRoles = useSelector(userRolesSelector);
  const articlesAsync = useAsync<
    PagedListOfFineArticleWithDefinedValues,
    any
  >();
  const [editDescriptionModalOpen, setEditDescriptionModalOpen] =
    useState<FineArticleWithDefinedValues | null>(null);

  const [articles, setArticles] = useState<FineArticleWithDefinedValues[]>([]);

  const isAdmin = userRoles.includes("ArticleAdmin");

  const changeArticleStateAsync = useAsync<void, any>();

  useEffect(() => {
    if (articlesAsync.data?.entities) {
      setArticles(articlesAsync.data.entities);
    }
  }, [articlesAsync.data]);

  useEffect(() => {
    articlesAsync.run(adminFineArticlesService.mergedWithDefined());
  }, []);

  const updateArticle = (
    item: FineArticleWithDefinedValues,
    state: FineArticleState,
  ) => {
    changeArticleStateAsync
      .run(adminFineArticlesService.changeState(item.article, state))
      .then(e => {
        if (!e) {
          requestData(
            new FineArticleWithDefinedValues({
              ...item,
              state,
            }),
          );
        }
      });
  };

  const requestData = (newArticle: FineArticleWithDefinedValues) => {
    setArticles(s =>
      s.map(item => (item.article === newArticle.article ? newArticle : item)),
    );
  };

  const sortedArticles = useMemo(() => {
    const articlesData = articles ?? [];

    return articlesData.sort((a, b) => {
      const condition =
        Number(
          a?.article
            .slice(0, 7)
            .replace("-", ".")
            .replaceAll("-", "")
            .slice(
              0,
              isNaN(Number(a?.article[a?.article.length - 1])) ? -1 : 10,
            ),
        ) <
        Number(
          b?.article
            .slice(0, 7)
            .replace("-", ".")
            .replaceAll("-", "")
            .slice(
              0,
              isNaN(Number(b?.article[b?.article.length - 1])) ? -1 : 10,
            ),
        );

      return condition ? -1 : 1;
    });
  }, [articles]);

  const [showOtherTexts, setShowOtherTexts] = useState<string[]>([]);

  const articlesDataInfo =
    sortedArticles?.map(item => [
      <div
        style={{
          minWidth: 80,
        }}
      >
        {item.article}
      </div>,
      <div>
        {item.externalArticleTexts
          ?.slice(0, showOtherTexts.includes(item.article) ? undefined : 1)
          ?.map((text, index) => (
            <>
              <div
                style={{
                  color: "#00c6be",
                  marginBottom: 8,
                  paddingTop: 8,
                  borderTopWidth: index !== 0 ? "0.5px" : "0px",
                  borderTopStyle: "solid",
                  borderTopColor: "gray",
                }}
              >
                {text.originalCodeName}
              </div>
              <div style={{ color: "black", marginBottom: 8 }}>
                {text.lawText}
              </div>
            </>
          ))}
        {(item.externalArticleTexts?.length ?? 0) > 1 && (
          <Button
            onClick={() =>
              setShowOtherTexts(s =>
                s.includes(item.article)
                  ? s.filter(i => i !== item.article)
                  : [...s, item.article],
              )
            }
          >
            {showOtherTexts.includes(item.article)
              ? "Hide Other Law text"
              : "Show Other Law text"}
          </Button>
        )}
      </div>,
      item.summary ?? "-",
      item.summaryEn ?? "-",
      item.title ?? "-",
      item.text ?? "-",
      <div
        style={{
          color:
            item.state === FineArticleState.Denied
              ? "red"
              : item.state === FineArticleState.Approved
                ? "green"
                : "black",
        }}
      >
        {item.state === FineArticleState.Initial
          ? "In review"
          : item.state ?? "-"}
      </div>,
      <Button onClick={() => setEditDescriptionModalOpen(item)}>
        <EditIcon />
      </Button>,
      ...(isAdmin
        ? [
            <Button
              onClick={() => updateArticle(item, FineArticleState.Approved)}
              disabled={
                item.state === FineArticleState.Approved &&
                changeArticleStateAsync.isLoading
              }
            >
              Approve
            </Button>,
            <Button
              onClick={() => updateArticle(item, FineArticleState.Denied)}
              disabled={
                item.state === FineArticleState.Denied &&
                changeArticleStateAsync.isLoading
              }
            >
              Decline
            </Button>,
          ]
        : []),
    ]) ?? [];

  const articlesTableData = [
    [
      "მუხლი",
      "Law text (Sagency)",
      "Summary Georgian (Borbalo)",
      "Summary English (Borbalo)",
      "Article Title (Borbalo)",
      "Article Text (Borbalo)",
      "სტატუსი",
      "",
      ...(isAdmin ? ["", ""] : []),
    ],
    ...articlesDataInfo,
  ];

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          flexDirection: "row",
        }}
      >
        <Typography variant="h3" mb={1.6}>
          {title}
          {articlesAsync.isLoading && (
            <CircularProgress
              sx={{
                alignSelf: "center",
                marginLeft: 3.6,
              }}
            />
          )}
        </Typography>
      </Box>
      <Typography variant="h4" mb={1.6}>
        Total {articlesAsync.data?.total}
      </Typography>
      <div className="column"></div>
      <MyTable tableData={articlesTableData} limitText />
      <AddDescriptionModal
        open={editDescriptionModalOpen}
        toggleModal={() => setEditDescriptionModalOpen(null)}
        requestData={requestData}
      />
    </>
  );
};

export default Articles;
