import { Box, Modal } from "@mui/material";
import uniqueBy from "lodash.uniqby";
import { memo, useEffect, useMemo, useState } from "react";
import {
  APIProvider,
  AdvancedMarker,
  Map,
  Pin,
} from "@vis.gl/react-google-maps";

import { FullFineCameraGroup } from "services/borbalo-main.service";
import {
  MapPolygons,
  MapRoutePoints,
  convertStringToCoordinates,
} from "components/AllCamerasOnMap/utils";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "99%",
  minWidth: 1126,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 2,
};

const AllCamerasOnMap = ({
  open,
  allCameras,
  handleCloseModal,
  handleEditClick,
  setActiveGroup,
}: {
  open: boolean;
  handleCloseModal: () => void;
  handleEditClick: (id: string) => void;
  allCameras: FullFineCameraGroup[] | undefined;
  setActiveGroup: React.Dispatch<
    React.SetStateAction<FullFineCameraGroup | undefined>
  >;
}) => {
  const [isMapLoaded, setIsMapLoaded] = useState(false);
  const markers = useMemo(() => {
    if (allCameras) {
      const allMarkersCoordinates = allCameras.map(({ cameras }) => {
        const coordinates = cameras.map(item => [
          ...[convertStringToCoordinates(item.coordinates)?.lontat ?? []],
        ]);

        return coordinates.flat();
      });

      return uniqueBy(
        allMarkersCoordinates.flat(),
        (item: number[]) => item[0]?.toString() + item[1]?.toString(),
      );
    }

    return [];
  }, [allCameras, open]);

  const cameras = allCameras;

  const polygons = useMemo(() => {
    if (allCameras) {
      const allPolygonsCoordinates = allCameras
        .map(({ cameras }) => {
          const polygons = cameras.map(item => [
            ...(item.cameraViewArea?.map(
              areaCoords =>
                convertStringToCoordinates(areaCoords)?.lontat ?? [],
            ) ?? []),
          ]);

          return polygons;
        })
        .filter(item => item.length > 0);

      return uniqueBy(allPolygonsCoordinates.flat(), function (item) {
        return JSON.stringify(item);
      });
    }

    return [];
  }, [allCameras, open]);

  const routesPoints = useMemo(() => {
    if (allCameras) {
      const allRoutePointsCoordinates = allCameras
        .map(({ manuallyBuiltRoutePoints, automaticallyBuiltRoutePoints }) => {
          const itemRoutesPoints = (
            manuallyBuiltRoutePoints || automaticallyBuiltRoutePoints
          )?.map(
            item =>
              convertStringToCoordinates(item.coordinates ?? "")?.lontat ?? [],
          );

          return itemRoutesPoints?.filter(item => item) ?? [];
        })
        .filter(item => item.length > 0);

      return uniqueBy(allRoutePointsCoordinates, function (item) {
        return JSON.stringify(item);
      });
    }

    return [];
  }, [allCameras, open]);

  useEffect(() => {
    if (!open) {
      setTimeout(() => {
        setIsMapLoaded(false);
      }, 1000);
    }
  }, [open]);

  if (!open) {
    return null;
  }

  return (
    <Modal
      open={open}
      onClose={handleCloseModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          ...style,
        }}
      >
        <div
          style={{
            width: "100%",
            height: window.innerHeight - 200,
          }}
        >
          <Box
            sx={{
              display: "flex",
              flex: 1,
              height: window.innerHeight - 200,
            }}
          >
            <APIProvider apiKey="AIzaSyAVr8gRuZGWGq18bv6Tx1TGPCA6uVhrLoc">
              <Map
                mapId={"allcameras"}
                zoom={14}
                center={{ lat: 41.6938, lng: 44.8015 }}
                onTilesLoaded={() => {
                  setIsMapLoaded(true);
                }}
              >
                {isMapLoaded && (
                  <>
                    {markers?.map(marker => (
                      <AdvancedMarker
                        key={marker[0] + marker[1]}
                        position={{
                          lat: marker[1],
                          lng: marker[0],
                        }}
                        onClick={() => {
                          cameras?.find(group => {
                            const camera = group.cameras.find(item => {
                              return (
                                convertStringToCoordinates(
                                  item.coordinates ?? "",
                                )?.lontat.toString() === marker.toString()
                              );
                            });
                            if (camera) {
                              if (group.cameras.length > 1) {
                                setActiveGroup(group);
                              } else {
                                setActiveGroup(undefined);
                              }
                              handleEditClick(camera.id);
                              return true;
                            }
                          });
                        }}
                        title={cameras
                          ?.map(({ cameras }) =>
                            cameras.map(item => {
                              return (
                                convertStringToCoordinates(
                                  item.coordinates ?? "",
                                )?.lontat,
                                marker,
                                [
                                  convertStringToCoordinates(
                                    item.coordinates ?? "",
                                  )?.lontat?.toString(),
                                  ...(item.cameraViewArea?.map(
                                    coordinates =>
                                      convertStringToCoordinates(
                                        coordinates ?? "",
                                      )?.lontat?.toString(),
                                  ) ?? []),
                                ].includes(marker?.toString())
                                  ? item.name
                                  : ""
                              );
                            }),
                          )
                          .flat()
                          .filter(item => item)
                          .join(", ")}
                      >
                        <Pin />
                      </AdvancedMarker>
                    ))}
                  </>
                )}
              </Map>
              {isMapLoaded && (
                <>
                  <MapPolygons polygons={polygons} />
                  <MapRoutePoints routesPoints={routesPoints} />
                </>
              )}
            </APIProvider>
          </Box>
        </div>
      </Box>
    </Modal>
  );
};

export default memo(AllCamerasOnMap);
