import { toast } from "react-toastify";
import { IconAlertCircle } from "icons/IconAlertCircle";
import { IconRadioChecked } from "icons/IconRadioChecked";

export const showSuccessToast = (message: string) => {
  toast(message, {
    type: "success",
    icon: () => <IconRadioChecked size={32} />,
  });
};

export const showErrorToast = (message: string) => {
  toast(message, {
    type: "error",
    icon: () => <IconAlertCircle size={28} />,
  });
};
