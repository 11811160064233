import { SvgIcon } from "icons/SvgIcon";

export const IconSquare = ({
  className,
  size,
}: {
  className?: string;
  size?: number;
}) => {
  return (
    <SvgIcon className={className} size={size}>
      <path
        fill="currentColor"
        d="M5.472 21.99h13.056a3.533 3.533 0 0 0 2.594-.863 3.46 3.46 0 0 0 .869-2.56V5.434a3.463 3.463 0 0 0-.869-2.56 3.532 3.532 0 0 0-2.594-.864H5.472a3.548 3.548 0 0 0-2.588.859 3.444 3.444 0 0 0-.874 2.566v13.131a3.445 3.445 0 0 0 .874 2.567 3.55 3.55 0 0 0 2.588.857Z"
      />
    </SvgIcon>
  );
};
