import { SvgIcon } from "./SvgIcon";

export const IconInstagram = ({
  className,
  size,
}: {
  className?: string;
  size?: number;
}) => {
  return (
    <SvgIcon className={className} size={size}>
      <path
        id="Path_32833"
        data-name="Path 32833"
        d="M-6.155,2.073H6.166C8.314,2.073,9.4,1,9.4-1.128v-12.4c0-2.116-1.085-3.19-3.233-3.19H-6.155c-2.148,0-3.244,1.074-3.244,3.19v12.4C-9.4,1-8.3,2.073-6.155,2.073Z"
        transform="translate(9.399 17.399)"
        fill="currentColor"
        opacity="0"
      />
      <g
        id="Group_2657"
        data-name="Group 2657"
        transform="translate(-30.999 -1578.715)"
      >
        <path
          id="Container"
          d="M-6.155,2.073H6.166C8.314,2.073,9.4.988,9.4-1.128v-12.4c0-2.116-1.085-3.2-3.233-3.2H-6.155c-2.148,0-3.244,1.074-3.244,3.2v12.4C-9.4,1-8.3,2.073-6.155,2.073Z"
          transform="translate(40.398 1595.44)"
          fill="currentColor"
          opacity="0"
        />
        <path
          id="Path_32830"
          data-name="Path 32830"
          d="M27.4,18c-2.553,0-2.873.011-3.876.057a6.9,6.9,0,0,0-2.281.437,4.808,4.808,0,0,0-2.75,2.749,6.866,6.866,0,0,0-.437,2.281c-.045,1-.057,1.323-.057,3.876s.011,2.872.057,3.875a6.908,6.908,0,0,0,.437,2.281,4.81,4.81,0,0,0,2.749,2.75,6.9,6.9,0,0,0,2.282.437c1,.046,1.323.057,3.875.057s2.872-.011,3.875-.057a6.908,6.908,0,0,0,2.282-.437,4.816,4.816,0,0,0,2.749-2.75,6.967,6.967,0,0,0,.437-2.281c.045-1,.057-1.322.057-3.875s-.012-2.873-.057-3.876a6.964,6.964,0,0,0-.437-2.281,4.809,4.809,0,0,0-2.749-2.749,6.919,6.919,0,0,0-2.283-.437c-1-.046-1.322-.057-3.875-.057Zm-.843,1.694H27.4c2.51,0,2.807.009,3.8.054a5.2,5.2,0,0,1,1.745.324,3.114,3.114,0,0,1,1.784,1.784,5.193,5.193,0,0,1,.324,1.745c.045.991.055,1.289.055,3.8s-.01,2.806-.055,3.8a5.2,5.2,0,0,1-.324,1.745,3.117,3.117,0,0,1-1.784,1.783,5.185,5.185,0,0,1-1.745.324c-.991.045-1.289.055-3.8.055s-2.807-.01-3.8-.055a5.214,5.214,0,0,1-1.745-.324,3.114,3.114,0,0,1-1.784-1.783,5.194,5.194,0,0,1-.324-1.745c-.045-.991-.054-1.288-.054-3.8s.009-2.806.054-3.8a5.2,5.2,0,0,1,.324-1.745,3.115,3.115,0,0,1,1.784-1.784,5.192,5.192,0,0,1,1.745-.324c.867-.039,1.2-.051,2.955-.053Zm5.861,1.561a1.128,1.128,0,1,0,1.128,1.128,1.128,1.128,0,0,0-1.128-1.128ZM27.4,22.572A4.826,4.826,0,1,0,32.226,27.4,4.827,4.827,0,0,0,27.4,22.572Zm0,1.694A3.133,3.133,0,1,1,24.267,27.4,3.133,3.133,0,0,1,27.4,24.266Z"
          transform="translate(13 1560.715)"
          fill="currentColor"
        />
      </g>
    </SvgIcon>
  );
};
