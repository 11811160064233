import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export enum RequestErrors {
  network = "network",
  error400WithCode = "error400WithCode",
  error400WithoutCode = "error400WithCode",
  server = "server",
  other = "other",
  failedAddCard = "failedAddCard",
  paymentFailed = "paymentFailed",
}

const initialState: {
  error: RequestErrors | null;
  isRetryButton: boolean;
} = { error: null, isRetryButton: false };

export const errorSlice = createSlice({
  name: "error",
  initialState: initialState,
  reducers: {
    toggleError: (state, { payload }: PayloadAction<RequestErrors | null>) => {
      if (!payload) {
        state.error = payload;
      }

      if (!state.error) {
        state.error = payload;
      }
    },

    changeModalButton: (state, { payload }: PayloadAction<boolean>) => {
      state.isRetryButton = payload;
    },
  },
});

export const { toggleError, changeModalButton } = errorSlice.actions;

export default errorSlice.reducer;
