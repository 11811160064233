import { Box, Button, Modal } from "@mui/material";

const PreviewModal = ({
  isOpen,
  toggleModal,
  children,
  buttons,
}: {
  isOpen?: boolean | undefined;
  toggleModal: () => void;
  children: React.ReactNode;
  buttons?: React.ReactNode;
}) => {
  return (
    <Modal
      open={!!isOpen}
      onClose={toggleModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          ...style,
        }}
      >
        <Button
          onClick={toggleModal}
          sx={{ position: "absolute", right: -4, top: 8 }}
        >
          <img
            src={"/assets/company/preview/close.svg"}
            width={28}
            height={28}
            alt="close-icon"
          />
        </Button>
        <Box
          sx={{
            gap: 3.2,
            height: "100%",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              height: "97%",
              gap: 3.2,
            }}
          >
            {children}
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: 1.6,
              justifyContent: "center",
            }}
          >
            {buttons}
          </Box>
        </Box>
        {/* <Button
          sx={{
            width: "100%",
          }}
          onClick={toggleModal}
        >
          Close
        </Button> */}
      </Box>
    </Modal>
  );
};

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  minHeight: 700,

  minWidth: 760,
  transform: "translate(-50%, -50%)",
  bgcolor: "#F1F4F5",
  border: "2px solid #000",
  boxShadow: 24,
  height: window.innerHeight - 50,
  overflowY: "auto",
  overflowX: "hidden",
  display: "flex",
  alignItems: "center",
  flexDirection: "column",
  p: 4,
};

export default PreviewModal;
