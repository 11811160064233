import { ReactNode, forwardRef } from "react";
import { ForwardedRef } from "react";
import clsx from "clsx";
import styles from "./ActionIcon.module.scss";

export const ActionIcon = forwardRef(
  (
    {
      children,
      className,
      disabled,
      onClick,
      ...rest
    }: {
      children: ReactNode;
      className?: string;
      disabled?: boolean;
      onClick?: () => void;
    },
    ref: ForwardedRef<HTMLButtonElement>,
  ) => {
    return (
      <button
        ref={ref}
        className={clsx(styles.root, className)}
        disabled={disabled}
        onClick={onClick}
        {...rest}
      >
        {children}
      </button>
    );
  },
);

ActionIcon.displayName = "ActionIcon";
