import { SvgIcon } from "./SvgIcon";

export const IconMail = ({
  className,
  size,
}: {
  className?: string;
  size?: number;
}) => {
  return (
    <SvgIcon className={className} size={size}>
      <path
        id="Path_32833"
        data-name="Path 32833"
        d="M.021-6.187a1.768,1.768,0,0,0,1.257-.645l8.852-8.755a3.051,3.051,0,0,0-2.084-.548H-8.325a2.66,2.66,0,0,0-1.815.494l8.895,8.809A1.751,1.751,0,0,0,.021-6.187ZM-11.011-.172-3.8-7.326l-7.229-7.133a3.442,3.442,0,0,0-.247,1.525V-1.708A3.159,3.159,0,0,0-11.011-.172ZM11.032-.193a3.42,3.42,0,0,0,.247-1.515V-12.934a3.312,3.312,0,0,0-.247-1.493l-7.2,7.1ZM-8.035,1.482H8.336a2.573,2.573,0,0,0,1.8-.494L2.782-6.306l-.655.666a3,3,0,0,1-2.105.988A2.984,2.984,0,0,1-2.084-5.64l-.666-.666L-10.1.978A3.254,3.254,0,0,0-8.035,1.482Z"
        transform="translate(11.279 16.135)"
        fill="currentColor"
      />
    </SvgIcon>
  );
};
