import { Button, CircularProgress, Typography } from "@mui/material";
import MyTable from "components/MyTable";
import useAsync from "hooks/useAsync";
import { useEffect, useState } from "react";
import { Ad, AdState, PagedListOfAd } from "services/borbalo-main.service";
import { adminAdsService } from "services/adminAds";
import { useParams } from "react-router-dom";
import moment from "moment";
import PreviewModal from "pages/Ads/PreviewModal";
import StateComponent from "components/StateComponent";

const Ads = ({ title }: { title: string }) => {
  const { id } = useParams();
  const { data, isLoading, run, isSuccess } = useAsync<PagedListOfAd, any>();
  const changeStateAsync = useAsync<void, any>();
  const [loadingId, setLoadingId] = useState<string | undefined>();

  const [isOpen, setIsOpen] = useState<Ad | undefined>(undefined);

  const getAllAds = () => {
    if (id) {
      run(adminAdsService.getAllAdsOfProvider(id));
    }
  };

  const closeModal = () => {
    setIsOpen(undefined);
  };

  useEffect(() => {
    getAllAds();
  }, [id]);

  useEffect(() => {
    if (isSuccess) {
      setLoadingId(undefined);
    }
  }, [isSuccess]);

  const stateChange = (adId: string, state: AdState) => {
    if (id) {
      setLoadingId(adId);
      changeStateAsync.run(adminAdsService.changeAdState(adId, state));
    }
  };

  useEffect(() => {
    if (changeStateAsync.isSuccess) {
      getAllAds();
    }
  }, [changeStateAsync.isSuccess]);

  const dataInfo =
    data?.entities?.map(item => [
      item.id,
      item.createdAt
        ? moment(item.createdAt).format("YYYY MM DD, HH:mm:ss")
        : "-",
      <StateComponent state={item.state} />,
      item.comments,
      <Button
        disabled={item.state === AdState.Active || loadingId === item.id}
        onClick={() => stateChange(item.id, AdState.Active)}
      >
        Activate
        <CircularProgress
          size={10}
          sx={{
            alignSelf: "center",
            ml: 1.6,
            opacity: Number(loadingId === item.id),
          }}
        />
      </Button>,
      <Button
        disabled={item.state === AdState.Deactivated || loadingId === item.id}
        onClick={() => stateChange(item.id, AdState.Deactivated)}
      >
        Deactivate
        <CircularProgress
          size={10}
          sx={{
            alignSelf: "center",
            ml: 1.6,
            opacity: Number(loadingId === item.id),
          }}
        />
      </Button>,
    ]) ?? [];

  const tableData = [
    ["id", "Create Date", "State", "Comment", "", ""],
    ...dataInfo,
  ];

  const onPress = (index: number) => {
    const entity = data?.entities?.[index];
    if (entity) {
      setIsOpen(entity);
    }
  };

  return (
    <>
      <Typography variant="h3" mb={1.6}>
        {title}
        {isLoading && (
          <CircularProgress
            sx={{
              alignSelf: "center",
              marginLeft: 3.6,
            }}
          />
        )}
      </Typography>
      <Typography variant="h4" mb={1.6}>
        Total {data?.total}
      </Typography>
      <div className="column"></div>
      <MyTable
        tableData={tableData}
        onPress={onPress}
        buttonText={"Show Preview"}
      />
      <PreviewModal isOpen={isOpen} toggleModal={closeModal} />
    </>
  );
};

export default Ads;
