import { Box, Button } from "@mui/material";
import {
  DiscountProgramBrand,
  DiscountProgramBrandLocation,
  DiscountProgramState,
} from "services/borbalo-main.service";
import StateComponent from "components/StateComponent";
import { useNavigate, useParams } from "react-router-dom";

const LocationsState = ({
  brand,
  getAllLocations,
  locations,
}: {
  brand: DiscountProgramBrand;
  getAllLocations: () => void;
  locations: DiscountProgramBrandLocation[];
}) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const originalIds = locations
    .map(item => item.originalLocationId ?? "")
    .filter(item => item);

  const brandLocations = locations
    .filter(item => {
      return (
        item.brandId === brand.id || item.brandId === brand.originalBrandId
      );
    })
    .filter(({ id }) => !originalIds.includes(id));

  const activeLocationsLength = brandLocations.filter(
    item => item.state === DiscountProgramState.Active,
  ).length;

  const locationsOnModerationLength = brandLocations.filter(
    item => item.state === DiscountProgramState.OnModeration,
  ).length;

  if (!brandLocations.length) {
    return <>-</>;
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
      }}
    >
      {!!activeLocationsLength && (
        <StateComponent
          state={DiscountProgramState.Active}
          marginBottom={1}
          count={activeLocationsLength}
        />
      )}
      {!!locationsOnModerationLength && (
        <StateComponent
          state={DiscountProgramState.OnModeration}
          marginBottom={1}
          count={locationsOnModerationLength}
        />
      )}
      <Button
        onClick={() =>
          navigate(`/discount-program-providers/${id}/${brand.id}/locations`)
        }
      >
        Locations
      </Button>
    </Box>
  );
};

export default LocationsState;
