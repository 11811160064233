import { SvgIcon } from "./SvgIcon";

export const IconPhone = ({
  className,
  size,
}: {
  className?: string;
  size?: number;
}) => {
  return (
    <SvgIcon className={className} size={size}>
      <path
        d="M-4.286-2.718C-1.332.236,2.267,2.524,5.2,2.524A4.406,4.406,0,0,0,8.6,1.042a3.1,3.1,0,0,0,.87-2A1.6,1.6,0,0,0,8.787-2.32L5.661-4.533a2.157,2.157,0,0,0-1.235-.5,1.974,1.974,0,0,0-1.354.73l-.73.72a.57.57,0,0,1-.387.15.962.962,0,0,1-.419-.107A16.009,16.009,0,0,1-1.182-5.812,14.526,14.526,0,0,1-3.437-8.529a.957.957,0,0,1-.118-.408.5.5,0,0,1,.161-.376l.72-.741a2.044,2.044,0,0,0,.72-1.354,2.239,2.239,0,0,0-.5-1.257l-2.191-3.072a1.673,1.673,0,0,0-1.429-.72,2.858,2.858,0,0,0-1.966.881A4.532,4.532,0,0,0-9.475-12.16C-9.475-9.217-7.229-5.661-4.286-2.718Z"
        transform="translate(9.475 16.457)"
        fill="currentColor"
      />
    </SvgIcon>
  );
};
