import { useDispatch, useSelector } from "react-redux";

import {
  Box,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from "@mui/material";
import { previewLanguageSelector } from "store/locations/selectors";
import { PreviewLanguage, setPreviewLanguage } from "store/locations/slice";

const languages = [
  {
    value: "en",
    label: "English",
  },
  {
    value: "ka",
    label: "ქართული",
  },
];

export const usePreviewLanguage = () => {
  const previewLanguage = useSelector(previewLanguageSelector);
  const dispatch = useDispatch();

  const handleChange = (event: SelectChangeEvent) => {
    dispatch(setPreviewLanguage(event.target.value as PreviewLanguage));
  };
  const isEn = previewLanguage === "en";

  const SelectComponent = (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        gap: 1.6,
      }}
    >
      <Typography variant="h6">Preview Language</Typography>
      <Select value={previewLanguage} onChange={handleChange}>
        {languages.map(language => (
          <MenuItem key={language.value} value={language.value}>
            {language.label}
          </MenuItem>
        ))}
      </Select>
    </Box>
  );

  return { isEn, previewLanguage, SelectComponent };
};
