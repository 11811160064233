import { SvgIcon } from "./SvgIcon";

export const IconX = ({
  className,
  size,
}: {
  className?: string;
  size?: number;
}) => {
  return (
    <SvgIcon className={className} size={size}>
      <path
        fill="currentColor"
        d="M3.492 21.987a1.443 1.443 0 0 1-1.063-.43 1.444 1.444 0 0 1-.43-1.063 1.423 1.423 0 0 1 .417-1.05l7.455-7.457-7.455-7.445a1.423 1.423 0 0 1-.417-1.05 1.444 1.444 0 0 1 .43-1.061A1.444 1.444 0 0 1 3.492 2a1.467 1.467 0 0 1 1.062.417l7.444 7.447 7.445-7.444a1.46 1.46 0 0 1 1.056-.417 1.48 1.48 0 0 1 1.395.917c.074.182.11.378.105.575a1.402 1.402 0 0 1-.43 1.05l-7.443 7.442 7.445 7.458a1.393 1.393 0 0 1 .43 1.043 1.479 1.479 0 0 1-.433 1.069 1.42 1.42 0 0 1-1.062.443 1.445 1.445 0 0 1-1.063-.43l-7.445-7.458-7.444 7.458a1.466 1.466 0 0 1-1.062.417Z"
      />
    </SvgIcon>
  );
};
