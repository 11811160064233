import { createSelector } from "reselect";
import type { RootState } from "store";

const authState = (state: RootState) => state.auth;

export const isUserSignedInSelector = createSelector(
  [authState],
  auth => auth.isUserSignedIn,
);

export const userRolesSelector = createSelector(
  [authState],
  auth => auth.roles,
);
