import { Button, CircularProgress, Typography } from "@mui/material";
import MyTable from "components/MyTable";
import useAsync from "hooks/useAsync";
import { useCallback, useEffect, useMemo, useState } from "react";
import {
  PagedListOfUserFeedback,
  UserFeedback,
} from "services/borbalo-main.service";
import { adminRadarFeedbacksService } from "services/adminRadarFeedbacksService";
import moment from "moment";
import Viewer from "pages/Fines/Viewer";

const UsersFeedbacks = ({ title }: { title: string }) => {
  const { data, isLoading, run } = useAsync<PagedListOfUserFeedback, any>();
  const [isViewerOpen, setIsViewerOpen] = useState(-1);
  const [contactedIds, setContactedIds] = useState<{
    [key: string]: string;
  }>({});

  const feedbacksContactedAsync = useAsync();

  const onContactedClick = (item: UserFeedback) => {
    if (item) {
      feedbacksContactedAsync
        .run(adminRadarFeedbacksService.markAsContacted(item.id!))
        .then(() => {
          setContactedIds(s => ({
            ...s,
            [item.id]: moment().format("DD.MM.YYYY HH:mm"),
          }));
        });
    }
  };

  const openImageViewer = useCallback((index: number) => {
    setIsViewerOpen(index);
  }, []);

  const closeImageViewer = () => {
    setIsViewerOpen(-1);
  };

  useEffect(() => {
    run(adminRadarFeedbacksService.getAll());
  }, []);

  const sortedData = useMemo(() => {
    if (data?.entities) {
      return data.entities.slice().sort((a, b) => {
        return a.createdAt < b.createdAt ? 1 : -1;
      });
    }

    return [];
  }, [data?.entities]);

  const dataInfo =
    sortedData?.map((item, index) => [
      item.userName,
      item.userPhone,
      moment(item.createdAt).format("DD.MM.YYYY HH:mm"),
      item.message,
      item.metadata ? item.metadata.deviceModel : "-",
      item.metadata ? item.metadata.os + ", " + item.metadata.osVersion : "-",
      <>
        <Button
          disabled={!item.attachment}
          onClick={() => openImageViewer(index)}
        >
          Show media
        </Button>
        {index === isViewerOpen && (
          <Viewer
            images={[`${item.attachment}`]}
            closeImageViewer={closeImageViewer}
          />
        )}
      </>,
      <>
        {item.contactedAt || contactedIds[item.id] ? (
          item.contactedAt ? (
            moment(item.contactedAt).format("DD.MM.YYYY HH:mm")
          ) : (
            contactedIds[item.id]
          )
        ) : (
          <Button
            disabled={feedbacksContactedAsync.isLoading}
            onClick={() => onContactedClick(item)}
          >
            Contacted
          </Button>
        )}
      </>,
    ]) ?? [];

  const tableData = [
    [
      "User Name",
      "User Phone",
      "Date",
      "message",
      "Device",
      "OS, Version",
      "attachment",
      "",
    ],
    ...dataInfo,
  ];

  return (
    <>
      <Typography variant="h3" mb={1.6}>
        {title}
        {isLoading && (
          <CircularProgress
            sx={{
              alignSelf: "center",
              marginLeft: 3.6,
            }}
          />
        )}
      </Typography>
      <Typography variant="h4" mb={1.6}>
        Total {data?.total}
      </Typography>
      <div className="column"></div>
      <MyTable tableData={tableData} />
    </>
  );
};

export default UsersFeedbacks;
