import React, { useEffect, useRef } from "react";
import clsx from "clsx";
import { IconChevronRight } from "icons/IconChevronRight";
import { IconPhone } from "icons/IconPhone";
import { IconWeb } from "icons/IconWeb";
import styles from "./LocationPreview.module.scss";
import {
  DiscountProgramBrand,
  DiscountProgramBrandLocation,
} from "services/borbalo-main.service";
import { IconDirections } from "icons/IconDirections";
import { usePreloadedImage } from "pages/ProvidersBrands/Previews/usePreloadedImage";
import { PreviewLanguage } from "store/locations/slice";

export const LocationPreview = ({
  location,
  language,
  brand,
}: {
  location?: DiscountProgramBrandLocation;
  language: PreviewLanguage;
  brand?: DiscountProgramBrand;
}) => {
  const contentRef = useRef<HTMLDivElement>(null);
  const isEn = language === "en";
  const imageSrc = `/assets/company/preview/${language}`;

  const brandToShow = brand;
  const goodToKnows = location?.goodToKnows;

  const { imageUrl } = usePreloadedImage(brandToShow?.shortLogoUrl);

  const backgroundImage = usePreloadedImage(location?.backgroundImageUrl);

  const imageFile0 = usePreloadedImage(location?.images[0]);
  const imageFile1 = usePreloadedImage(location?.images[1]);
  const imageFile2 = usePreloadedImage(location?.images[2]);
  const imageFile3 = usePreloadedImage(location?.images[3]);
  const imageFile4 = usePreloadedImage(location?.images[4]);

  const images = [
    imageFile0,
    imageFile1,
    imageFile2,
    imageFile3,
    imageFile4,
  ].filter(item => !!item.imageUrl);

  useEffect(() => {
    if (location) {
      contentRef.current?.scrollTo(0, 1000);
    }
  }, [location]);

  const socials = [
    {
      icon: IconDirections,
      title: isEn ? "Way" : "გზა",
    },
    {
      icon: IconPhone,
      title: isEn ? "Call" : "დარეკვა",
    },
    {
      icon: IconWeb,
      title: isEn ? "Webpage" : "ვებგვერდი",
    },
  ];

  const details = [
    {
      title: isEn ? "Phone" : "დარეკვა",
      text: location?.phone,
      colorClassName: "color-main",
    },
    {
      title: isEn ? "Webpage" : "ვებგვერდი",
      text: brandToShow?.websiteLink,
      colorClassName: "color-main",
    },
    {
      title: isEn ? "Address" : "მისამართი",
      text: location?.address,
      colorClassName: "color-white",
    },
  ].filter(item => !!item.text);

  const texts = {
    goodToKnows: isEn ? "Good to know" : "საინტერესო ინფორმაცია",
    rating: isEn ? "Rate it" : "შეაფასეთ",
    details: isEn ? "Details" : "დეტალები",
    seeAll: isEn ? "See all" : "ყველას ნახვა",
  };

  return (
    <div className={styles["wrapper"]}>
      <div className={styles["header"]}></div>
      <div className={styles["formsheet-wrapper"]}>
        <div className={styles["background-image-wrapper"]}>
          {backgroundImage?.imageUrl ? (
            <img
              src={backgroundImage?.imageUrl}
              width={375}
              height={180}
              alt="bgimg"
            />
          ) : (
            <img
              src={`${imageSrc}/no-photo.svg`}
              width={375}
              height={0}
              alt="no bgimg"
              style={{
                height: "auto",
              }}
            />
          )}
          <div className={styles["location-logo-wrapper"]}>
            <img
              src={imageUrl || "/assets/payment/borbalo-logo.svg"}
              width={64}
              height={64}
              alt={"square-logo"}
            />
            {/* {profile?.mainDiscountAmount && (
              <div className={clsx(styles["discount-text"], "bold32-text")}>
                -{profile?.mainDiscountAmount}%
              </div>
            )} */}
          </div>
          <img
            src={"/assets/company/preview/close.svg"}
            width={28}
            height={28}
            alt="close-icon"
            className={styles["close-icon"]}
          />
        </div>
        <div className={styles["content"]} ref={contentRef}>
          <div className="mx16">
            <div className="row-space-between">
              <div className="column-space-around overflow-hidden">
                <div className="medium24-text color-white break-word mb4">
                  {location?.locationName}
                </div>
                <div className="medium14-text color-white break-word">
                  {brandToShow?.subCategory?.name}
                  {location?.address && (
                    <span className="color-whiteGrey">
                      {" "}
                      • {location?.region}, {location?.city}
                    </span>
                  )}
                </div>
              </div>
            </div>
            <div className="row py16 gap16">
              {socials.map(social => (
                <div
                  key={social.title}
                  className={clsx(styles["button-with-icon"], "pointer")}
                >
                  <social.icon size={24} className={"color-white"} />
                  <div className="regular12-text color-white">
                    {social.title}
                  </div>
                </div>
              ))}
            </div>
            <img
              src={`${imageSrc}/info-section.svg`}
              width={343}
              height={48.5}
              alt="info-section"
            />
            <div className={styles["images-wrapper"]}>
              {location?.images?.length ? (
                <>
                  {images?.map(image => (
                    <div
                      className={clsx(styles[language ?? "ka"])}
                      key={image.imageUrl}
                    >
                      <img
                        src={image.imageUrl}
                        alt={image.imageFile?.name}
                        width={0}
                        height={178}
                        className={clsx(styles["image-cover"], styles["auto"])}
                      />
                    </div>
                  ))}
                  <img
                    src={`${imageSrc}/add-image.svg`}
                    alt={"add-images"}
                    width={142}
                    height={178}
                    className={styles["image-cover"]}
                  />
                </>
              ) : (
                <>
                  <div className={clsx(styles[language ?? "ka"])}>
                    <img
                      src={`${imageSrc}/no-photo.svg`}
                      alt={"no-iamges"}
                      width={142}
                      height={178}
                      className={styles["image-cover"]}
                    />
                  </div>
                  <div className={clsx(styles[language ?? "ka"])}>
                    <img
                      src={`${imageSrc}/no-photo.svg`}
                      alt={"no-iamges"}
                      width={142}
                      height={178}
                      className={styles["image-cover"]}
                    />
                  </div>
                  <img
                    src={`${imageSrc}/add-image.svg`}
                    alt={"add-images"}
                    width={142}
                    height={178}
                    className={styles["image-cover"]}
                  />
                </>
              )}
            </div>
            {goodToKnows && goodToKnows.length > 0 && (
              <>
                <div className="mx16 medium18-text color-white mb8">
                  {texts.goodToKnows}
                </div>
                <div className={styles["section-wrapper"]}>
                  {goodToKnows?.map(goodToKnow => (
                    <React.Fragment key={goodToKnow.name}>
                      <div className="regular16-text mb16 color-white">
                        • {goodToKnow.name}
                      </div>
                    </React.Fragment>
                  ))}
                  <div className="regular16-text color-main">
                    {texts.seeAll}{" "}
                    <IconChevronRight
                      className={clsx("color-main", styles["arrow-icon"])}
                      size={16}
                    />
                  </div>
                </div>
              </>
            )}
            <div className="mx16 medium18-text color-white mb8">
              {texts.rating}
            </div>
            <img
              src={`${imageSrc}/review-section.svg`}
              width={343}
              height={224}
              alt="info-section"
              className="mb32"
            />
            <div className="mx16 medium18-text color-white mb8">
              {texts.details}
            </div>
            <img
              src={`${imageSrc}/details-section.svg`}
              width={343}
              height={74}
              alt="details-section"
              className="mb8"
            />
            {details.length > 0 && (
              <div className={styles["section-wrapper"]}>
                {details.map((item, index) => (
                  <React.Fragment key={item.title}>
                    {index > 0 && (
                      <div className={clsx(styles["separator"], "my16")} />
                    )}
                    <div className="regular16-text mb4 color-whiteGrey">
                      {item.title}
                    </div>
                    <div
                      className={clsx("regular16-text", item.colorClassName)}
                    >
                      {item.text}
                    </div>
                  </React.Fragment>
                ))}
              </div>
            )}
            <img
              src={`${imageSrc}/last-section.svg`}
              width={343}
              height={116}
              alt="last-section"
              className="mb32"
            />
          </div>
        </div>
      </div>
    </div>
  );
};
