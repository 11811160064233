import { SvgIcon } from "./SvgIcon";

export const IconAlertCircle = ({
  className,
  size,
}: {
  className?: string;
  size?: number;
}) => {
  return (
    <SvgIcon className={className} size={size}>
      <path
        fill="currentColor"
        d="M11.986 2.001a10.172 10.172 0 0 0-7.091 3.044A9.833 9.833 0 0 0 2 12.174 9.816 9.816 0 0 0 11.834 22h.178A10.092 10.092 0 0 0 22 11.827 9.805 9.805 0 0 0 11.986 2ZM10.75 15.786a1.23 1.23 0 0 1 1.208-1.275h.022a1.273 1.273 0 0 1 1.27 1.225 1.23 1.23 0 0 1-1.208 1.275h-.022a1.274 1.274 0 0 1-1.27-1.225Zm.417-3.368v-5a.833.833 0 1 1 1.667 0v5a.833.833 0 1 1-1.667 0Z"
      />
    </SvgIcon>
  );
};
