import { AdBanner } from "services/borbalo-main.service";

import "./styles.css";

const SmallBannerPreview = ({
  data,
  images,
}: {
  data: AdBanner;
  images: { [id: string]: string }[];
  isGe?: boolean;
}) => {
  const image = images.find(item => !!item[data.id])?.[data.id];

  return (
    <div>
      <div
        style={{
          width: 332,
          height: 56,
          backgroundColor: "#fff",
          borderRadius: 14,
          overflow: "hidden",
        }}
      >
        {image && (
          <img
            src={image}
            alt="large banner"
            width={348}
            height={66}
            style={{
              objectFit: "contain",
            }}
          />
        )}
      </div>
    </div>
  );
};

export default SmallBannerPreview;
