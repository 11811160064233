import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useReducer,
  useRef,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { showErrorToast } from "components/toast";
import { MainErrorCode } from "services/borbalo-main.service";
import { errorSelector } from "store/error/selectors";
import { RequestErrors, toggleError } from "store/error/slice";
import { PreviewLanguage } from "store/locations/slice";
import axiosInstance from "services/index";

type CommonStateType<T, U> = {
  status: "idle" | "pending" | "resolved" | "rejected";
  data: T | null;
  error: U | null;
};

type ReducerType<T, U> = (
  state: CommonStateType<T, U>,
  action: Partial<CommonStateType<T, U>>,
) => CommonStateType<T, U>;

const reducer = <T, U>(
  state: CommonStateType<T, U>,
  action: Partial<CommonStateType<T, U>>,
) => ({
  ...state,
  ...action,
});

function useAsync<DataType, ErrorType>(
  errorsIsProcessed?: boolean,
  previewLanguage?: PreviewLanguage,
) {
  useEffect(() => {
    if (previewLanguage) {
      const localeForRequest = previewLanguage === "ka" ? "ka-ge" : "en";
      axiosInstance.defaults.headers.common["Accept-Language"] =
        localeForRequest;
    }
  }, [previewLanguage]);

  const dispatch = useDispatch();
  const globalError = useSelector(errorSelector);
  const initialStateRef = useRef<CommonStateType<DataType, ErrorType>>({
    status: "idle",
    data: null,
    error: null,
  });

  const [{ status, data, error }, setState] = useReducer<
    ReducerType<DataType, ErrorType>
  >(reducer, initialStateRef.current);

  const safeSetState = useSafeDispatch(setState);

  const setData = useCallback(
    (newData: DataType) => safeSetState({ data: newData, status: "resolved" }),
    [safeSetState],
  );
  const setError = useCallback(
    (newError: ErrorType | null) =>
      safeSetState({ error: newError, status: newError ? "rejected" : "idle" }),
    [safeSetState],
  );
  const reset = useCallback(
    () => safeSetState(initialStateRef.current),
    [safeSetState],
  );

  const run = useCallback(
    (promise: Promise<DataType>) => {
      safeSetState({ error: null, status: "pending" });
      return promise.then(
        newData => {
          setData(newData);

          return newData;
        },
        newError => {
          setError(newError);

          showErrorToast(newError.displayText ?? newError.title);

          Promise.reject(error);

          if (globalError || newError?.status === 401) {
            return;
          }

          // timeout or 500+
          if (newError?.code === "ECONNABORTED" || newError?.status >= 500) {
            return dispatch(toggleError(RequestErrors.server));
          }

          if (!errorsIsProcessed && newError?.status >= 400) {
            if (newError?.title === MainErrorCode.PaymentFailed) {
              return dispatch(toggleError(RequestErrors.paymentFailed));
            }

            return dispatch(
              toggleError(
                newError?.errorCode
                  ? RequestErrors.error400WithCode
                  : RequestErrors.error400WithoutCode,
              ),
            );
          }

          if (!errorsIsProcessed && newError?.status !== 204) {
            return dispatch(toggleError(RequestErrors.other));
          }

          return;
        },
      );
    },
    [safeSetState, setData, setError],
  );

  return {
    isIdle: status === "idle",
    isLoading: status === "pending",
    isError: status === "rejected",
    isSuccess: status === "resolved",

    setData,
    setError,
    error,
    status,
    data,
    run,
    reset,
  };
}

//safes from dispatch on unmounted component
function useSafeDispatch<P>(dispatch: React.Dispatch<P>) {
  const mounted = useRef(false);
  useLayoutEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);
  return useCallback(
    (action: P) => (mounted.current ? dispatch(action) : undefined),
    [dispatch],
  );
}

export default useAsync;
