import { Box, Modal } from "@mui/material";
import { APIProvider, Map, Marker } from "@vis.gl/react-google-maps";
import { convertStringToCoordinates } from "components/AllCamerasOnMap/utils";
import { memo } from "react";
// @ts-ignore
import Streetview from "react-google-streetview";

import {
  AdminGroupedRadarReports,
  RadarReportType,
} from "services/borbalo-main.service";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "99%",
  minWidth: 1126,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 2,
};

export const icons = {
  [RadarReportType.Accident]: require("./assets/accident.png"),
  [RadarReportType.Hazard]: require("./assets/hazard.png"),
  [RadarReportType.Police]: require("./assets/police.png"),
  [RadarReportType.Camera]: require("./assets/camera.png"),
};

const AllReportsOnMap = ({
  open,
  handleCloseModal,
  allReports,
}: {
  open: boolean | AdminGroupedRadarReports;
  handleCloseModal: () => void;
  allReports: AdminGroupedRadarReports[];
}) => {
  if (!open) {
    return null;
  }

  const stringCoordinates =
    typeof open !== "boolean"
      ? convertStringToCoordinates(open.location)
      : undefined;

  return (
    <Modal
      open={!!open}
      onClose={handleCloseModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          ...style,
        }}
      >
        <div
          style={{
            width: "100%",
            height: window.innerHeight - 100,
          }}
        >
          <Box
            sx={{
              display: "flex",
              flex: 1,
              flexDirection: "column",
              height: window.innerHeight - 100,
            }}
          >
            {stringCoordinates &&
              typeof open !== "boolean" &&
              open.type === RadarReportType.Camera && (
                <div
                  style={{
                    backgroundColor: "#000",
                    height: 400,
                    width: "100%",
                    marginBottom: 20,
                  }}
                >
                  <Streetview
                    apiKey="AIzaSyAVr8gRuZGWGq18bv6Tx1TGPCA6uVhrLoc"
                    streetViewPanoramaOptions={{
                      position: {
                        lat: stringCoordinates.lat,
                        lng: stringCoordinates.lng,
                      },
                      addressControl: true,
                      panControl: true,
                      zoomControl: true,
                    }}
                  />
                </div>
              )}
            <APIProvider apiKey="AIzaSyAVr8gRuZGWGq18bv6Tx1TGPCA6uVhrLoc">
              <Map
                id={"allreports"}
                zoom={12}
                center={
                  stringCoordinates
                    ? { lat: stringCoordinates.lat, lng: stringCoordinates.lng }
                    : { lat: 41.6938, lng: 44.8015 }
                }
              >
                <>
                  {stringCoordinates && typeof open !== "boolean" ? (
                    <>
                      <Marker
                        position={{
                          lat: stringCoordinates.lat,
                          lng: stringCoordinates.lng,
                        }}
                        icon={icons[open.type]}
                      />
                    </>
                  ) : (
                    <>
                      {allReports?.map(report => {
                        const coordinates = report.location
                          ?.replace(" ", "")
                          ?.split(",");

                        if (coordinates[0] && coordinates[1]) {
                          return (
                            <Marker
                              key={coordinates[0] + coordinates[1]}
                              position={{
                                lat: Number(coordinates[0]),
                                lng: Number(coordinates[1]),
                              }}
                              icon={icons[report.type]}
                            />
                          );
                        } else {
                          return null;
                        }
                      })}
                    </>
                  )}
                </>
              </Map>
            </APIProvider>
          </Box>
        </div>
      </Box>
    </Modal>
  );
};

export default memo(AllReportsOnMap);
