import clsx from "clsx";
import useAsync from "hooks/useAsync";
import React, {
  ChangeEvent,
  KeyboardEvent,
  useEffect,
  useRef,
  useState,
} from "react";
import { useDispatch } from "react-redux";
import { accountService } from "services/account";
import {
  LoginByPasswordRequest,
  TokenResponse,
} from "services/borbalo-main.service";
import { login, setRoles } from "store/auth/slice";
import { getTokensFromStorage, setTokensDataToKeychain } from "utils/tokens";
import styles from "./Login.module.scss";
import ArrowSvg from "images/arrow.svg";
import { useNavigate } from "react-router-dom";

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const loginAsync = useAsync<TokenResponse, any>(true);

  const [isError, setIsError] = useState(false);
  const passwordInputRef = useRef<HTMLInputElement>(null);
  const [isArrowActive, setIsArrowActive] = useState(false);
  const [isArrowPassword, setIsArrowPassword] = useState(false);
  const [formData, setFormData] = useState({ login: "", password: "" });

  const resetErrors = () => {
    if (isError) {
      setIsError(false);
    }
    if (loginAsync.isError) {
      loginAsync.reset();
    }
  };

  const handleArrowClick = async () => {
    resetErrors();
    if (!isArrowActive) {
      return;
    }
    if (!isArrowPassword) {
      setIsArrowPassword(true);
      return setTimeout(() => passwordInputRef.current?.focus(), 300);
    }
    if (!formData.password) {
      return setIsError(true);
    }
    loginAsync.run(
      accountService.loginByPassword(new LoginByPasswordRequest(formData)),
    );
  };

  useEffect(() => {
    if (!formData.login.length && isArrowPassword) {
      setIsArrowPassword(false);
      setIsArrowActive(false);
      setTimeout(() => {
        setFormData(state => ({
          ...state,
          password: "",
        }));
      }, 300);
    }
  }, [isArrowPassword, formData.login]);

  useEffect(() => {
    if (!isArrowActive && formData.login.length) {
      setIsArrowActive(true);
    }
    if (isArrowActive && !formData.login.length) {
      setIsArrowActive(false);
    }
  }, [formData.login, isArrowActive]);

  const handleInputChange = ({ target }: ChangeEvent<HTMLInputElement>) => {
    resetErrors();
    setFormData(state => ({
      ...state,
      [target.name]: target.value,
    }));
  };

  const handleInputKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter" || (!isArrowPassword && e.key === "Tab")) {
      e.preventDefault();
      handleArrowClick();
    }
  };

  useEffect(() => {
    if (
      formData.password &&
      formData.login &&
      (!isArrowActive || !isArrowPassword)
    ) {
      setIsArrowPassword(true);
      setIsArrowActive(true);
    }
  }, [formData]);

  useEffect(() => {
    const handleLogin = async () => {
      if (loginAsync.isSuccess && loginAsync.data) {
        setTokensDataToKeychain(loginAsync.data);
        const tokens = await getTokensFromStorage();
        // TODO: Remove
        if (
          tokens?.roles.includes("borbalo-admin") ||
          tokens?.roles.includes("MapStaff") ||
          tokens?.roles.includes("ArticleEditor") ||
          tokens?.roles.includes("RadarFeedbackSupport")
        ) {
          dispatch(setRoles(tokens?.roles ?? []));
          dispatch(login());
        } else {
          localStorage.removeItem("auth");
          loginAsync.setError("wrong user");
        }
      }
    };

    handleLogin();
  }, [dispatch, loginAsync.isSuccess, loginAsync.data]);

  return (
    <main className={styles.main}>
      <div className={styles.wrapper}>
        <span className={clsx("bold44-text", styles.title)}>
          Borbalo Admin Panel
        </span>
        <div
          className={clsx(styles["input-wrapper"], {
            [styles.error]: isError || loginAsync.isError,
          })}
          style={{
            height: isArrowPassword ? 140 : 70,
          }}
        >
          <input
            value={formData.login}
            onChange={handleInputChange}
            className={clsx([styles.input, "input-text"])}
            placeholder={"Login"}
            name="login"
            type="email"
            onKeyDown={handleInputKeyDown}
          />
          <div className="divider ml32" />
          <input
            ref={passwordInputRef}
            value={formData.password}
            onChange={handleInputChange}
            className={clsx([styles.input, "input-text"])}
            placeholder={"Password"}
            name="password"
            type="password"
            onKeyDown={handleInputKeyDown}
          />
          <button
            className={clsx("unstyled-button w-auto flex", styles.arrow, {
              [styles["arrow-active"]]: isArrowActive,
              [styles["arrow-password"]]: isArrowPassword,
            })}
            onClick={handleArrowClick}
          >
            <img src={ArrowSvg} width={36} height={36} alt="arrow" />
          </button>
        </div>
      </div>
    </main>
  );
};

export default Login;
